export const PERMISSIONS_DEPENDENCIES = {
    "validate:program": [],
    "validate:special_pricing_program": [],
    "read:lead_tab": [],
    "read:lead": [],
    "read:lead_own": [],
    "create:lead": [],
    "update:lead": [],
    "delete:lead": [],
    "create:lead_import": [],
    "update:lead_flag": [],
    "update:lead_unflag": [],
    "update:lead_agent": [],
    "update:lead_agent_own": [],
    "read:lead_details": [],
    "update:lead_convert": [],
    "update:lead_re_agent": [],
    "update:lead_re_agent_own": [],
    "update:lead_funding_agent": [],
    "update:lead_funding_agent_own": [],
    "update:lead_loan_officer": [],
    "update:lead_loan_officer_own": [],
    "read:lead_overall": [],
    "update:lead_overall": ["read:lead_overall"],
    "create:lead_credit_scores": ["read:lead_overall"],
    "update:lead_credit_scores": ["read:lead_overall"],
    "delete:lead_credit_scores": ["read:lead_overall"],
    "read:lead_program": [],
    "create:lead_program": ["read:lead_program"],
    "update:lead_program_contract_closed": ["read:lead_program"],
    "update:lead_program_status": ["read:lead_program"],
    "read:lead_program_agent_fee": ["read:lead_program"],
    "read:lead_program_price": ["read:lead_program"],
    "read:lead_program_info": ["read:lead_program"],
    "delete:lead_program": ["read:lead_program"],
    "read:lead_financial": [],
    "update:lead_financial": ["read:lead_financial"],
    "read:lead_document": [],
    "import:lead_document": ["read:lead_document"],
    "delete:lead_document": ["read:lead_document"],
    "read:lead_notes": [],
    "create:lead_notes": ["read:lead_notes"],
    "update:lead_notes": ["read:lead_notes"],
    "delete:lead_notes": ["read:lead_notes"],
    "read:lead_conversation": [],
    "create:lead_conversation": ["read:lead_conversation"],

    "read:client_tab": [],
    "read:client": [],
    "read:client_own": [],
    "read:client_bankruptcy": [],
    "create:client": [],
    "update:client": [],
    "delete:client": [],
    "create:client_import": [],
    "update:client_agent": [],
    "update:client_agent_own": [],
    "update:client_flag": [],
    "update:client_unflag": [],
    "read:client_activity": [],
    "read:client_details": [],
    "update:client_re_agent": [],
    "update:client_re_agent_own": [],
    "update:client_funding_agent": [],
    "update:client_funding_agent_own": [],
    "update:client_loan_officer": [],
    "update:client_loan_officer_own": [],
    "read:client_production": [],
    "read:client_overall": [],
    "update:client_overall": ["read:client_overall"],
    "create:client_credit_scores": ["read:client_overall"],
    "update:client_credit_scores": ["read:client_overall"],
    "delete:client_credit_scores": ["read:client_overall"],
    "read:client_program": [],
    "create:client_program": ["read:client_program"],
    "update:client_program_contract_closed": ["read:client_program"],
    "update:client_program_status": ["read:client_program"],
    "read:client_program_agent_fee": ["read:client_program"],
    "read:client_program_price": ["read:client_program"],
    "read:client_program_info": ["read:client_program"],
    "delete:client_program": ["read:client_program"],
    "read:client_bankruptcy_tab": [],
    "update:client_bankruptcy_tab": ["read:client_bankruptcy_tab"],
    "read:client_payment": [],
    "unlock:client_payment_methods": ["read:client_payment"],
    "update:client_payment_methods": ["read:client_payment"],
    "read:client_payment_transactions": ["read:client_payment"],
    "create:client_payment_transactions": ["read:client_payment"],
    "update:client_payment_transactions": ["read:client_payment"],
    "read:client_payment_re_transactions": ["read:client_payment"],
    "create:client_payment_re_transactions": ["read:client_payment"],
    "update:client_payment_re_transactions": ["read:client_payment"],
    "delete:client_payment_re_transactions": ["read:client_payment"],
    "update:client_payment_info": ["read:client_payment"],
    "read:client_payment_fees": ["read:client_payment"],
    "create:client_payment_fees": ["read:client_payment"],
    "update:client_payment_fees": ["read:client_payment"],
    "delete:client_payment_fees": ["read:client_payment"],
    "read:client_payment_fees_transactions": ["read:client_payment"],
    "create:client_payment_fees_transactions": ["read:client_payment"],
    "update:client_payment_fees_transactions": ["read:client_payment"],
    "read:client_financial": [],
    "update:client_financial": ["read:client_financial"],
    "read:client_processing": [],
    "update:client_processing": ["read:client_processing"],
    "read:client_processing_process": ["read:client_processing"],
    "create:client_processing_process": [
        "read:client_processing",
        "read:client_processing_process",
    ],
    "delete:client_processing_process": [
        "read:client_processing",
        "read:client_processing_process",
    ],
    "update:client_processing_process": [
        "read:client_processing",
        "read:client_processing_process",
    ],
    "read:client_processing_platform_access": ["read:client_processing"],
    "create:client_processing_platform_access": ["read:client_processing", "read:client_processing_platform_access"],
    "delete:client_processing_platform_access": ["read:client_processing", "read:client_processing_platform_access"],
    "update:client_processing_platform_access": ["read:client_processing", "read:client_processing_platform_access"],
    "read:client_funding": [],
    "read:client_funding_credit_lines": ["read:client_funding"],
    "create:client_funding_credit_lines": ["read:client_funding", "read:client_funding_credit_lines"],
    "delete:client_funding_credit_lines": ["read:client_funding", "read:client_funding_credit_lines"],
    "update:client_funding_credit_lines": ["read:client_funding", "read:client_funding_credit_lines"],
    "read:client_funding_mortgage": ["read:client_funding"],
    "create:client_funding_mortgage": ["read:client_funding", "read:client_funding_mortgage"],
    "delete:client_funding_mortgage": ["read:client_funding", "read:client_funding_mortgage"],
    "update:client_funding_mortgage": ["read:client_funding", "read:client_funding_mortgage"],
    "read:client_funding_bank_access": ["read:client_funding"],
    "create:client_funding_bank_access": ["read:client_funding", "read:client_funding_bank_access"],
    "delete:client_funding_bank_access": ["read:client_funding", "read:client_funding_bank_access"],
    "update:client_funding_bank_access": ["read:client_funding", "read:client_funding_bank_access"],
    "read:client_tradelines": [],
    "update:client_tradelines": ["read:client_tradelines"],
    "read:client_tradelines_tradeline": ["read:client_tradelines"],
    "create:client_tradelines_tradeline": [
        "read:client_tradelines",
        "read:client_tradelines_tradeline",
    ],
    "delete:client_tradelines_tradeline": [
        "read:client_tradelines",
        "read:client_tradelines_tradeline",
    ],
    "update:client_tradelines_tradeline": [
        "read:client_tradelines",
        "read:client_tradelines_tradeline",
    ],
    "read:client_settlements": [],
    "read:client_settlements_settlement": ["read:client_settlements"],
    "create:client_settlements_settlement": [
        "read:client_settlements",
        "read:client_settlements_settlement",
    ],
    "delete:client_settlements_settlement": [
        "read:client_settlements",
        "read:client_settlements_settlement",
    ],
    "update:client_settlements_settlement": [
        "read:client_settlements",
        "read:client_settlements_settlement",
    ],
    "create:client_settlements_notification": [
        "read:client_settlements",
        "read:client_settlements_settlement",
    ],
    "read:client_settlements_credit_card": ["read:client_settlements"],
    "create:client_settlements_credit_card": [
        "read:client_settlements",
        "read:client_settlements_credit_card",
    ],
    "delete:client_settlements_credit_card": [
        "read:client_settlements",
        "read:client_settlements_credit_card",
    ],
    "update:client_settlements_credit_card": [
        "read:client_settlements",
        "read:client_settlements_credit_card",
    ],
    "read:client_accounts": [],
    "create:client_accounts": ["read:client_accounts"],
    "delete:client_accounts": ["read:client_accounts"],
    "update:client_accounts": ["read:client_accounts"],
    "read:client_violations": [],
    "create:client_violations": ["read:client_violations"],
    "delete:client_violations": ["read:client_violations"],
    "update:client_violations": ["read:client_violations"],
    "read:client_document": [],
    "import:client_document": ["read:client_document"],
    "delete:client_document": ["read:client_document"],
    "read:client_notes": [],
    "create:client_notes": ["read:client_notes"],
    "delete:client_notes": ["read:client_notes"],
    "update:client_notes": ["read:client_notes"],
    "read:client_conversation": [],
    "create:client_conversation": ["read:client_conversation"],

    "read:employee_tab": [],
    "read:employee": [],
    "read:employee_not_own": [],
    "create:employee": [],
    "delete:employee": [],
    "read:employee_overall": [],
    "update:employee_overall": ["read:employee_overall"],
    "read:employee_contact": [],
    "create:employee_notes": ["read:employee_contact"],
    "update:employee_notes": ["read:employee_contact"],
    "delete:employee_notes": ["read:employee_contact"],
    "import:employee_document": ["read:employee_contact"],
    "delete:employee_document": ["read:employee_contact"],
    "read:employee_commissions": [],
    "update:employee_commissions": ["read:employee_commissions"],

    "read:activity_tab": [],

    "read:campaign_tab": [],
    "read:campaign_bulk_messages": [],
    "create:campaign_bulk_messages": ["read:campaign_bulk_messages"],
    "read:campaign": [],
    "create:campaign": ["read:campaign"],
    "delete:campaign": ["read:campaign"],
    "update:campaign": ["read:campaign"],
    "read:campaign_templates": [],
    "create:campaign_templates": ["read:campaign_templates"],
    "delete:campaign_templates": ["read:campaign_templates"],
    "update:campaign_templates": ["read:campaign_templates"],

    "read:user_tab": [],
    "read:user": [],
    "create:user": ["read:user"],
    "update:user": ["read:user"],
    "delete:user": ["read:user"],
    "update:user_role_permissions": ["read:user", "update:user"],
    "update:user_password": ["read:user", "update:user"],

    "read:calendar_tab": [],
    "read:calendar_appointments": [],
    "read:calendar_appointments_own": [],
    "create:calendar_appointments": [],
    "update:calendar_appointments": [],
    "delete:calendar_appointments": [],
    "create:calendar_appointments_other": [],
    "create:calendar_appointments_superior": ["create:calendar_appointments"],
    "create:calendar_appointments_inferior": ["create:calendar_appointments"],
    "read:calendar_appointments_assigned_by": [],
    "read:calendar_tasks": [],
    "read:calendar_tasks_own": [],
    "create:calendar_tasks": [],
    "update:calendar_tasks": [],
    "delete:calendar_tasks": [],
    "create:calendar_tasks_other": [],
    "create:calendar_tasks_superior": ["create:calendar_tasks"],
    "create:calendar_tasks_inferior": ["create:calendar_tasks"],
    "read:calendar_tasks_assigned_by": [],

    "read:settings_tab": [],
    "read:settings_dropdowns": [],
    "read:settings_round_robin": [],
    "read:settings_creditors": [],
    "read:settings_cr_pricing": [],
    "update:settings_dropdowns": ["read:settings_dropdowns"],
    "update:settings_round_robin": ["read:settings_round_robin"],
    "update:settings_creditors": ["read:settings_creditors"],
    "update:settings_cr_pricing": ["read:settings_cr_pricing"],
};

export const MULTI_OPTION_PERMISSIONS_DEPENDENCIES = {
    "validate:program": [],
    "validate:special_pricing_program": [],

    "read:lead_tab": [],
    "read:lead": [],
    "read:lead_own": [],
    "create:lead": ["read:lead", "read:lead_own"],
    "update:lead": ["read:lead", "read:lead_own"],
    "delete:lead": ["read:lead", "read:lead_own"],
    "create:lead_import": ["read:lead", "read:lead_own"],
    "update:lead_flag": ["read:lead", "read:lead_own"],
    "update:lead_unflag": ["read:lead", "read:lead_own"],
    "update:lead_re_agent": ["read:lead", "read:lead_own"],
    "update:lead_re_agent_own": ["read:lead", "read:lead_own"],
    "update:lead_funding_agent": ["read:lead", "read:lead_own"],
    "update:lead_funding_agent_own": ["read:lead", "read:lead_own"],
    "update:lead_loan_officer": ["read:lead", "read:lead_own"],
    "update:lead_loan_officer_own": ["read:lead", "read:lead_own"],
    "update:lead_agent": ["read:lead", "read:lead_own"],
    "update:lead_agent_own": ["read:lead", "read:lead_own"],
    "read:lead_details": ["read:lead", "read:lead_own"],
    "update:lead_convert": ["read:lead", "read:lead_own"],
    "read:lead_overall": [],
    "update:lead_overall": [],
    "create:lead_credit_scores": [],
    "update:lead_credit_scores": [],
    "delete:lead_credit_scores": [],
    "read:lead_program": [],
    "create:lead_program": [],
    "update:lead_program_contract_closed": [],
    "update:lead_program_status": [],
    "read:lead_program_agent_fee": [],
    "read:lead_program_price": [],
    "read:lead_program_info": [],
    "delete:lead_program": [],
    "read:lead_financial": [],
    "update:lead_financial": [],
    "read:lead_document": [],
    "import:lead_document": [],
    "delete:lead_document": [],
    "read:lead_notes": [],
    "create:lead_notes": [],
    "update:lead_notes": [],
    "delete:lead_notes": [],
    "read:lead_conversation": [],
    "create:lead_conversation": [],

    "read:client_tab": [],
    "read:client": [],
    "read:client_own": [],
    "read:client_bankruptcy": [],
    "create:client": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "delete:client": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "create:client_import": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_re_agent": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_re_agent_own": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_funding_agent": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_funding_agent_own": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_loan_officer": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_loan_officer_own": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_agent": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_agent_own": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_flag": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "update:client_unflag": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "read:client_activity": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "read:client_details": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "read:client_production": ["read:client", "read:client_own", "read:client_bankruptcy"],
    "read:client_overall": [],
    "update:client_overall": [],
    "create:client_credit_scores": [],
    "update:client_credit_scores": [],
    "delete:client_credit_scores": [],
    "read:client_program": [],
    "create:client_program": [],
    "update:client_program_contract_closed": [],
    "update:client_program_status": [],
    "read:client_program_agent_fee": [],
    "read:client_program_price": [],
    "read:client_program_info": [],
    "delete:client_program": [],
    "read:client_bankruptcy_tab": [],
    "update:client_bankruptcy_tab": [],
    "read:client_payment": [],
    "unlock:client_payment_methods": [],
    "update:client_payment_methods": [],
    "read:client_payment_transactions": [],
    "create:client_payment_transactions": [],
    "update:client_payment_transactions": [],
    "read:client_payment_re_transactions": [],
    "create:client_payment_re_transactions": [],
    "update:client_payment_re_transactions": [],
    "delete:client_payment_re_transactions": [],
    "update:client_payment_info": [],
    "read:client_payment_fees": [],
    "create:client_payment_fees": [],
    "update:client_payment_fees": [],
    "delete:client_payment_fees": [],
    "read:client_payment_fees_transactions": [],
    "create:client_payment_fees_transactions": [],
    "update:client_payment_fees_transactions": [],
    "read:client_financial": [],
    "update:client_financial": [],
    "read:client_processing": [],
    "update:client_processing": [],
    "read:client_processing_process": [],
    "create:client_processing_process": [],
    "delete:client_processing_process": [],
    "update:client_processing_process": [],
    "read:client_processing_platform_access": [],
    "create:client_processing_platform_access": [],
    "delete:client_processing_platform_access": [],
    "update:client_processing_platform_access": [],
    "read:client_tradelines": [],
    "update:client_tradelines": [],
    "read:client_tradelines_tradeline": [],
    "create:client_tradelines_tradeline": [],
    "delete:client_tradelines_tradeline": [],
    "update:client_tradelines_tradeline": [],
    "read:client_settlements": [],
    "read:client_settlements_settlement": [],
    "create:client_settlements_settlement": [],
    "delete:client_settlements_settlement": [],
    "update:client_settlements_settlement": [],
    "create:client_settlements_notification": [],
    "read:client_settlements_credit_card": [],
    "create:client_settlements_credit_card": [],
    "delete:client_settlements_credit_card": [],
    "update:client_settlements_credit_card": [],
    "read:client_accounts": [],
    "create:client_accounts": [],
    "delete:client_accounts": [],
    "update:client_accounts": [],
    "read:client_violations": [],
    "create:client_violations": [],
    "delete:client_violations": [],
    "update:client_violations": [],
    "read:client_document": [],
    "import:client_document": [],
    "delete:client_document": [],
    "read:client_notes": [],
    "create:client_notes": [],
    "delete:client_notes": [],
    "update:client_notes": [],
    "read:client_conversation": [],
    "create:client_conversation": [],

    "read:employee_tab": [],
    "read:employee": [],
    "read:employee_not_own": [],
    "create:employee": ["read:employee", "read:employee_not_own"],
    "delete:employee": ["read:employee", "read:employee_not_own"],
    "read:employee_overall": ["read:employee", "read:employee_not_own"],
    "update:employee_overall": ["read:employee", "read:employee_not_own"],
    "read:employee_contact": ["read:employee", "read:employee_not_own"],
    "create:employee_notes": ["read:employee", "read:employee_not_own"],
    "update:employee_notes": ["read:employee", "read:employee_not_own"],
    "delete:employee_notes": ["read:employee", "read:employee_not_own"],
    "import:employee_document": ["read:employee", "read:employee_not_own"],
    "delete:employee_document": ["read:employee", "read:employee_not_own"],
    "read:employee_commissions": ["read:employee", "read:employee_not_own"],
    "update:employee_commissions": ["read:employee", "read:employee_not_own"],

    "read:activity_tab": [],

    "read:campaign_tab": [],
    "create:campaign_bulk_messages": [],
    "read:campaign_bulk_messages": [],
    "read:campaign": [],
    "create:campaign": [],
    "delete:campaign": [],
    "update:campaign": [],
    "read:campaign_templates": [],
    "create:campaign_templates": [],
    "delete:campaign_templates": [],
    "update:campaign_templates": [],

    "read:user_tab": [],
    "read:user": [],
    "create:user": [],
    "update:user": [],
    "delete:user": [],
    "update:user_role_permissions": [],
    "update:user_password": [],

    "read:calendar_tab": [],
    "read:calendar_appointments": [],
    "read:calendar_appointments_own": [],
    "create:calendar_appointments": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "update:calendar_appointments": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "delete:calendar_appointments": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "create:calendar_appointments_other": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "create:calendar_appointments_superior": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "create:calendar_appointments_inferior": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "read:calendar_appointments_assigned_by": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "read:calendar_tasks": [],
    "read:calendar_tasks_own": [],
    "create:calendar_tasks": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "update:calendar_tasks": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "delete:calendar_tasks": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "create:calendar_tasks_other": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "create:calendar_tasks_superior": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "create:calendar_tasks_inferior": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "read:calendar_tasks_assigned_by": ["read:calendar_tasks", "read:calendar_tasks_own"],

    "read:settings_tab": [],
    "read:settings_dropdowns": [],
    "read:settings_round_robin": [],
    "read:settings_creditors": [],
    "read:settings_cr_pricing": [],
    "update:settings_dropdowns": [],
    "update:settings_round_robin": [],
    "update:settings_creditors": [],
    "update:settings_cr_pricing": [],
};

export const REMOVE_PERMISSIONS_DEPENDENCIES = {
    "validate:program": [],
    "validate:special_pricing_program": [],

    "read:lead_tab": [],
    "read:lead": [
        "create:lead",
        "update:lead",
        "delete:lead",
        "create:lead_import",
        "update:lead_flag",
        "update:lead_unflag",
        "update:lead_re_agent",
        "update:lead_re_agent_own",
        "update:lead_funding_agent",
        "update:lead_funding_agent_own",
        "update:lead_loan_officer",
        "update:lead_loan_officer_own",
        "update:lead_agent",
        "update:lead_agent_own",
        "read:lead_details",
        "update:lead_convert",
    ],
    "read:lead_own": [
        "create:lead",
        "update:lead",
        "delete:lead",
        "create:lead_import",
        "update:lead_flag",
        "update:lead_unflag",
        "update:lead_re_agent",
        "update:lead_re_agent_own",
        "update:lead_funding_agent",
        "update:lead_funding_agent_own",
        "update:lead_loan_officer",
        "update:lead_loan_officer_own",
        "update:lead_agent",
        "update:lead_agent_own",
        "read:lead_details",
        "update:lead_convert",
    ],
    "create:lead": [],
    "update:lead": [],
    "delete:lead": [],
    "create:lead_import": [],
    "update:lead_flag": [],
    "update:lead_unflag": [],
    "update:lead_re_agent": [],
    "update:lead_re_agent_own": [],
    "update:lead_funding_agent": [],
    "update:lead_funding_agent_own": [],
    "update:lead_loan_officer": [],
    "update:lead_loan_officer_own": [],
    "update:lead_agent": [],
    "update:lead_agent_own": [],
    "read:lead_details": [],
    "update:lead_convert": [],
    "read:lead_overall": [
        "update:lead_overall",
        "create:lead_credit_scores",
        "update:lead_credit_scores",
        "delete:lead_credit_scores",
    ],
    "update:lead_overall": [],
    "create:lead_credit_scores": [],
    "update:lead_credit_scores": [],
    "delete:lead_credit_scores": [],
    "read:lead_program": [
        "create:lead_program",
        "update:lead_program_contract_closed",
        "update:lead_program_status",
        "read:lead_program_agent_fee",
        "read:lead_program_price",
        "read:lead_program_info",
        "delete:lead_program",
    ],
    "create:lead_program": [],
    "update:lead_program_contract_closed": [],
    "update:lead_program_status": [],
    "read:lead_program_agent_fee": [],
    "read:lead_program_price": [],
    "read:lead_program_info": [],
    "delete:lead_program": [],
    "read:lead_financial": ["update:lead_financial"],
    "update:lead_financial": [],
    "read:lead_document": ["import:lead_document", "delete:lead_document"],
    "import:lead_document": [],
    "delete:lead_document": [],
    "read:lead_notes": ["create:lead_notes", "update:lead_notes", "delete:lead_notes"],
    "create:lead_notes": [],
    "update:lead_notes": [],
    "delete:lead_notes": [],
    "read:lead_conversation": ["create:lead_conversation"],
    "create:lead_conversation": [],

    "read:client_tab": [],
    "read:client": [
        "create:client",
        "update:client",
        "delete:client",
        "create:client_import",
        "update:client_re_agent",
        "update:client_re_agent_own",
        "update:client_funding_agent",
        "update:client_funding_agent_own",
        "update:client_loan_officer",
        "update:client_loan_officer_own",
        "update:client_agent",
        "update:client_agent_own",
        "update:client_flag",
        "update:client_unflag",
        "read:client_activity",
        "read:client_details",
        "read:client_production",
    ],
    "read:client_own": [
        "create:client",
        "update:client",
        "delete:client",
        "create:client_import",
        "update:client_re_agent",
        "update:client_re_agent_own",
        "update:client_funding_agent",
        "update:client_funding_agent_own",
        "update:client_loan_officer",
        "update:client_loan_officer_own",
        "update:client_agent",
        "update:client_agent_own",
        "update:client_flag",
        "update:client_unflag",
        "read:client_activity",
        "read:client_details",
        "read:client_production",
    ],
    "read:client_bankruptcy": [
        "create:client",
        "update:client",
        "delete:client",
        "create:client_import",
        "update:client_re_agent",
        "update:client_re_agent_own",
        "update:client_funding_agent",
        "update:client_funding_agent_own",
        "update:client_loan_officer",
        "update:client_loan_officer_own",
        "update:client_agent",
        "update:client_agent_own",
        "update:client_flag",
        "update:client_unflag",
        "read:client_activity",
        "read:client_details",
        "read:client_production",
    ],
    "create:client": [],
    "update:client": [],
    "delete:client": [],
    "create:client_import": [],
    "update:client_re_agent": [],
    "update:client_re_agent_own": [],
    "update:client_funding_agent": [],
    "update:client_funding_agent_own": [],
    "update:client_loan_officer": [],
    "update:client_loan_officer_own": [],
    "update:client_agent": [],
    "update:client_agent_own": [],
    "update:client_flag": [],
    "update:client_unflag": [],
    "read:client_activity": [],
    "read:client_details": [],
    "read:client_production": [],
    "read:client_overall": [
        "update:client_overall",
        "create:client_credit_scores",
        "update:client_credit_scores",
        "delete:client_credit_scores",
    ],
    "update:client_overall": [],
    "create:client_credit_scores": [],
    "update:client_credit_scores": [],
    "delete:client_credit_scores": [],
    "read:client_program": [
        "create:client_program",
        "update:client_program_contract_closed",
        "update:client_program_status",
        "read:client_program_agent_fee",
        "read:client_program_price",
        "read:client_program_info",
        "delete:client_program",
    ],
    "create:client_program": [],
    "update:client_program_contract_closed": [],
    "update:client_program_status": [],
    "read:client_program_agent_fee": [],
    "read:client_program_price": [],
    "read:client_program_info": [],
    "delete:client_program": [],
    "read:client_bankruptcy_tab": ["update:client_bankruptcy_tab"],
    "update:client_bankruptcy_tab": [],
    "read:client_payment": [
        "unlock:client_payment_methods",
        "update:client_payment_methods",
        "read:client_payment_transactions",
        "create:client_payment_transactions",
        "update:client_payment_transactions",
        "read:client_payment_re_transactions",
        "create:client_payment_re_transactions",
        "update:client_payment_re_transactions",
        "delete:client_payment_re_transactions",
        "update:client_payment_info",
        "read:client_payment_fees",
        "create:client_payment_fees",
        "update:client_payment_fees",
        "delete:client_payment_fees",
        "read:client_payment_fees_transactions",
        "create:client_payment_fees_transactions",
        "update:client_payment_fees_transactions",
    ],
    "unlock:client_payment_methods": [],
    "update:client_payment_methods": [],
    "read:client_payment_transactions": [],
    "create:client_payment_transactions": [],
    "update:client_payment_transactions": [],
    "read:client_payment_re_transactions": [],
    "create:client_payment_re_transactions": [],
    "update:client_payment_re_transactions": [],
    "delete:client_payment_re_transactions": [],
    "update:client_payment_info": [],
    "read:client_payment_fees": [],
    "create:client_payment_fees": [],
    "update:client_payment_fees": [],
    "delete:client_payment_fees": [],
    "read:client_payment_fees_transactions": [],
    "create:client_payment_fees_transactions": [],
    "update:client_payment_fees_transactions": [],
    "read:client_financial": ["update:client_financial"],
    "update:client_financial": [],
    "read:client_processing": [
        "update:client_processing",
        "read:client_processing_process",
        "create:client_processing_process",
        "delete:client_processing_process",
        "update:client_processing_process",
        "read:client_processing_platform_access",
        "create:client_processing_platform_access",
        "delete:client_processing_platform_access",
        "update:client_processing_platform_access",
    ],
    "update:client_processing": [],
    "read:client_processing_process": [
        "create:client_processing_process",
        "delete:client_processing_process",
        "update:client_processing_process",
    ],
    "create:client_processing_process": [],
    "delete:client_processing_process": [],
    "update:client_processing_process": [],
    "read:client_processing_platform_access": [
        "create:client_processing_platform_access",
        "delete:client_processing_platform_access",
        "update:client_processing_platform_access",
    ],
    "create:client_processing_platform_access": [],
    "delete:client_processing_platform_access": [],
    "update:client_processing_platform_access": [],
    "read:client_funding": [
        "read:client_funding_credit_lines",
        "create:client_funding_credit_lines",
        "delete:client_funding_credit_lines",
        "update:client_funding_credit_lines",
    ],
    "read:client_funding_credit_lines": [
        "create:client_funding_credit_lines",
        "delete:client_funding_credit_lines",
        "update:client_funding_credit_lines",
    ],
    "create:client_funding_credit_lines": [],
    "delete:client_funding_credit_lines": [],
    "update:client_funding_credit_lines": [],

    "read:client_funding_mortgage": ["create:client_funding_mortgage", "delete:client_funding_mortgage", "update:client_funding_mortgage"],
    "create:client_funding_mortgage": [],
    "delete:client_funding_mortgage": [],
    "update:client_funding_mortgage": [],
    "read:client_funding_bank_access": ["create:client_funding_bank_access", "delete:client_funding_bank_access", "update:client_funding_bank_access"],
    "create:client_funding_bank_access": [],
    "delete:client_funding_bank_access": [],
    "update:client_funding_bank_access": [],
    "read:client_tradelines": [
        "update:client_tradelines",
        "read:client_tradelines_tradeline",
        "create:client_tradelines_tradeline",
        "delete:client_tradelines_tradeline",
        "update:client_tradelines_tradeline",
    ],
    "update:client_tradelines": [],
    "read:client_tradelines_tradeline": [
        "create:client_tradelines_tradeline",
        "delete:client_tradelines_tradeline",
        "update:client_tradelines_tradeline",
    ],
    "create:client_tradelines_tradeline": [],
    "delete:client_tradelines_tradeline": [],
    "update:client_tradelines_tradeline": [],
    "read:client_settlements": [
        "read:client_settlements_settlement",
        "create:client_settlements_settlement",
        "delete:client_settlements_settlement",
        "update:client_settlements_settlement",
        "create:client_settlements_notification",
        "read:client_settlements_credit_card",
        "create:client_settlements_credit_card",
        "delete:client_settlements_credit_card",
        "update:client_settlements_credit_card",
    ],
    "read:client_settlements_settlement": [
        "create:client_settlements_settlement",
        "delete:client_settlements_settlement",
        "update:client_settlements_settlement",
        "create:client_settlements_notification",
    ],
    "create:client_settlements_settlement": [],
    "delete:client_settlements_settlement": [],
    "update:client_settlements_settlement": [],
    "create:client_settlements_notification": [],
    "read:client_settlements_credit_card": [
        "create:client_settlements_credit_card",
        "delete:client_settlements_credit_card",
        "update:client_settlements_credit_card",
    ],
    "create:client_settlements_credit_card": [],
    "delete:client_settlements_credit_card": [],
    "update:client_settlements_credit_card": [],
    "read:client_accounts": [
        "create:client_accounts",
        "delete:client_accounts",
        "update:client_accounts",
    ],
    "create:client_accounts": [],
    "delete:client_accounts": [],
    "update:client_accounts": [],
    "read:client_violations": [
        "create:client_violations",
        "delete:client_violations",
        "update:client_violations",
    ],
    "create:client_violations": [],
    "delete:client_violations": [],
    "update:client_violations": [],
    "read:client_document": ["import:client_document", "delete:client_document"],
    "import:client_document": [],
    "delete:client_document": [],
    "read:client_notes": ["create:client_notes", "delete:client_notes", "update:client_notes"],
    "create:client_notes": [],
    "delete:client_notes": [],
    "update:client_notes": [],
    "read:client_conversation": ["create:client_conversation"],
    "create:client_conversation": [],

    "read:employee_tab": [],
    "read:employee": [
        "create:employee",
        "delete:employee",
        "read:employee_overall",
        "update:employee_overall",
        "read:employee_contact",
        "create:employee_notes",
        "update:employee_notes",
        "delete:employee_notes",
        "import:employee_document",
        "delete:employee_document",
        "read:employee_commissions",
        "update:employee_commissions",
    ],
    "read:employee_not_own": [
        "create:employee",
        "delete:employee",
        "read:employee_overall",
        "update:employee_overall",
        "read:employee_contact",
        "create:employee_notes",
        "update:employee_notes",
        "delete:employee_notes",
        "import:employee_document",
        "delete:employee_document",
        "read:employee_commissions",
        "update:employee_commissions",
    ],
    "create:employee": [],
    "delete:employee": [],
    "read:employee_overall": ["update:employee_overall"],
    "update:employee_overall": [],
    "read:employee_contact": [
        "create:employee_notes",
        "update:employee_notes",
        "delete:employee_notes",
        "import:employee_document",
        "delete:employee_document",
    ],
    "create:employee_notes": [],
    "update:employee_notes": [],
    "delete:employee_notes": [],
    "import:employee_document": [],
    "delete:employee_document": [],
    "read:employee_commissions": ["update:employee_commissions"],
    "update:employee_commissions": [],

    "read:activity_tab": [],

    "read:campaign_tab": [],
    "read:campaign_bulk_messages": ["create:campaign_bulk_messages"],
    "create:campaign_bulk_messages": [],
    "read:campaign": ["create:campaign", "delete:campaign", "update:campaign"],
    "create:campaign": [],
    "delete:campaign": [],
    "update:campaign": [],
    "read:campaign_templates": [
        "create:campaign_templates",
        "delete:campaign_templates",
        "update:campaign_templates",
    ],
    "create:campaign_templates": [],
    "delete:campaign_templates": [],
    "update:campaign_templates": [],

    "read:user_tab": [],
    "read:user": [
        "create:user",
        "update:user",
        "delete:user",
        "update:user_role_permissions",
        "update:user_password",
    ],
    "create:user": [],
    "update:user": ["update:user_role_permissions", "update:user_password"],
    "delete:user": [],
    "update:user_role_permissions": [],
    "update:user_password": [],

    "read:calendar_tab": [],
    "read:calendar_appointments": [
        "create:calendar_appointments",
        "update:calendar_appointments",
        "delete:calendar_appointments",
        "create:calendar_appointments_other",
        "create:calendar_appointments_superior",
        "create:calendar_appointments_inferior",
        "read:calendar_appointments_assigned_by",
    ],
    "read:calendar_appointments_own": [
        "create:calendar_appointments",
        "update:calendar_appointments",
        "delete:calendar_appointments",
        "create:calendar_appointments_other",
        "create:calendar_appointments_superior",
        "create:calendar_appointments_inferior",
        "read:calendar_appointments_assigned_by",
    ],
    "create:calendar_appointments": [],
    "update:calendar_appointments": [],
    "delete:calendar_appointments": [],
    "create:calendar_appointments_other": [],
    "create:calendar_appointments_superior": [],
    "create:calendar_appointments_inferior": [],
    "read:calendar_appointments_assigned_by": [],
    "read:calendar_tasks": [
        "create:calendar_tasks",
        "update:calendar_tasks",
        "delete:calendar_tasks",
        "create:calendar_tasks_other",
        "create:calendar_tasks_superior",
        "create:calendar_tasks_inferior",
        "read:calendar_tasks_assigned_by",
    ],
    "read:calendar_tasks_own": [
        "create:calendar_tasks",
        "update:calendar_tasks",
        "delete:calendar_tasks",
        "create:calendar_tasks_other",
        "create:calendar_tasks_superior",
        "create:calendar_tasks_inferior",
        "read:calendar_tasks_assigned_by",
    ],
    "create:calendar_tasks": [],
    "update:calendar_tasks": [],
    "delete:calendar_tasks": [],
    "create:calendar_tasks_other": [],
    "create:calendar_tasks_superior": [],
    "create:calendar_tasks_inferior": [],
    "read:calendar_tasks_assigned_by": [],

    "read:settings_tab": [],
    "read:settings_dropdowns": ["update:settings_dropdowns"],
    "read:settings_round_robin": ["update:settings_round_robin"],
    "read:settings_creditors": ["update:settings_creditors"],
    "read:settings_cr_pricing": ["update:settings_cr_pricing"],
    "update:settings_dropdowns": [],
    "update:settings_round_robin": [],
    "update:settings_creditors": [],
    "update:settings_cr_pricing": [],
};
