/**
 * This component is made to be used with React-Form-Hooks
 */

import { RegisterOptions, UseFormRegister } from "react-hook-form";

type InputProps = {
    name: string;
    title?: string;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    isDisabled?: boolean;
    inputRegister: UseFormRegister<any>;
    type?: "text" | "date" | "number" | "email" | "password" | "time" | "textarea" | "month";
    validation?: RegisterOptions;
    children?: JSX.Element | false | undefined;
    attributes?: any;
    spanClassname?: string;
};

export const InputRFH = ({
    name,
    title,
    placeholder,
    className,
    inputClassName,
    titleClassname,
    editing = true,
    inputRegister,
    type = "text",
    validation = {},
    children,
    attributes,
    spanClassname,
}: InputProps) => {
    return (
        <div className={`flex flex-col ${className ? className : ""}`}>
            {title && (
                <div className={`text-r-medium-small-2 ${titleClassname ? titleClassname : ""}`}>
                    <span
                        className={`block overflow-y overflow-x-hidden break-words ${spanClassname}`}
                    >
                        {title}
                    </span>
                </div>
            )}
            <div className="w-full">
                <input
                    {...attributes}
                    className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                      focus:border-reyna-primary transition-all rounded-[7px] py-1 px-2 mb-4 h-[34px]
                      ${inputClassName ? inputClassName : " bg-white"} ${editing === false ? " bg-reyna-grey-3 " : ""
                        } `}
                    type={type}
                    data-testid={`${name}-test`}
                    placeholder={placeholder}
                    disabled={editing === false}
                    {...inputRegister(name, { ...validation })}
                />
                {/* You can pass a component to display stuff under the input. Its usually used for
                displaying error msges */}
                {children && children}
            </div>
        </div>
    );
};
