import { useMemo, useRef } from "react";
import Select from "react-select";

// Ocultar los componentes no deseados (Input, Placeholder)
const CustomNullComponent = () => null;

type InputProps = {
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: any) => void;
    className?: string;
    value?: any;
    selectOptions: any[];
    isObject?: boolean;
    objectKeyValue?: string;
    objectKeyDisplay?: string;
    isMulti?: boolean;
    menuPosition?: "fixed";
    icon: any;
};

export const DropDownIcon = ({
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    value = "",
    selectOptions,
    isObject = false,
    objectKeyValue = "",
    objectKeyDisplay = "",
    isMulti = false,
    menuPosition,
    icon,
}: InputProps) => {

    const CustomDropdownIndicator = () => {
        return (
            <div className="flex items-center justify-center size-[24px]">{icon}</div>
        )
    }

    const selectRef = useRef<any>(null);
    const onChange = (val: any, action: any) => {
        if (action.action === "clear") {
            isMulti && onChangeFunction && onChangeFunction([]);
            !isMulti && onChangeFunction && onChangeFunction("");
        } else if (action.action === "remove-value") {
            isMulti && onChangeFunction && onChangeFunction(val);
        } else if (action.action === "select-option") {
            if (onChangeFunction) {
                isMulti ? onChangeFunction(val) : onChangeFunction(val.value);
            }
        } else {
            onChangeFunction && onChangeFunction(val.value);
        }
    };

    const options = useMemo(() => {
        return selectOptions.map((o) => ({
            value: isObject ? o[objectKeyValue] : o,
            label: isObject ? o[objectKeyDisplay] : o,
        }));
    }, [selectOptions, isObject, objectKeyDisplay, objectKeyValue])

    const defaultSelectStyles = () => {
        return {
            control: (state: any) => ({
                border: "0px solid rgba(228, 229, 232, 1)",
                display: "flex",
                outline: "0px solid transparent",
            }),

        };
    };
    return (
        <div className={`${className} flex flex-col justify-start items-start gap-y-2 w-full`}>
            <Select
                ref={selectRef}
                name={name}
                styles={defaultSelectStyles()}
                classNames={{
                    menuList: () => "custom-scroll-blue min-w-[200px]",
                    menu: () => "min-w-[200px] -right-1",
                    valueContainer: () => "w-0 !p-0",
                    indicatorSeparator: () => "hidden",
                    indicatorsContainer: () => "!p-0",
                    control: () => "!border-none hover:!border-none !border-[transparent]"
                }}
                isSearchable={false}
                className={`react-select size-[24px]`}
                menuPosition={"fixed"}
                maxMenuHeight={200}
                options={options as never[]}
                isLoading={options === null}
                value={
                    value === "" || !value
                        ? null
                        : (!isMulti &&
                            ({
                                value: isObject ? value.value : value,
                                label: isObject ? value.label : value,
                            } as never)) ||
                        (value as never[])
                }
                placeholder={placeholder ? placeholder : "Select Option"}
                isMulti={isMulti}
                onChange={onChange as any}
                menuPlacement="auto"
                components={{
                    // Control: CustomControl, // Reemplazar el Control con el ícono
                    // Input: CustomNullComponent, // Eliminar el Input
                    Placeholder: CustomNullComponent, // Eliminar el Placeholder
                    IndicatorSeparator: CustomNullComponent,
                    DropdownIndicator: CustomDropdownIndicator,
                }}
            />
        </div>
    );
};
