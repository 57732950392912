import { useEffect } from "react";
import { get, useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { DropdownDynamicList } from "../../../components/dropdowns/DropDownDynamicList";
import { ErrorText } from "../../../components/forms/ErrorText";
import { API, FORM_MSG } from "../../../config";
import { useHandleError, useUpdate } from "../../../hooks";
import { CloseIcon } from "../../../icons";
import { CancelProgramOptions } from "../../../types/ClientProgram";

interface ValidateProgramPopupParams {
    clickFunctionCancelButton?: () => void;
    handleOnSuccess: () => void;
    proposal_id: string;
    person_id: string;
    price?: number;
    lead?: boolean;
    program_id?: string;
    info: any,
}

export const CancelProgramPopup = ({
    clickFunctionCancelButton,
    handleOnSuccess,
    proposal_id,
    person_id,
    price,
    program_id,
    lead = false,
    info
}: ValidateProgramPopupParams) => {
    const {
        register,
        watch,
        setValue,
        control,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm<CancelProgramOptions>({ mode: "onBlur" });

    const options = watch();

    /**
     * For deeply nested object properties, errors have to access
     * via the get method.
     * @param key Property key
     * @returns error object
     */
    const getErrorType = (key: string) => get(errors, key)?.type;

    const handleClosePopup = () => {
        if (clickFunctionCancelButton) clickFunctionCancelButton();
    };

    const onSubmitSuccess = () => {
        handleClosePopup();
        handleOnSuccess();
    };

    /**
     * A hook to handle errors.
     */
    const handleOnError = useHandleError();
    /**
     * The following hook is used for saving the leads form.
     * If saving is successfull, the handleOnSuccess function is called &
     * if an error occurs while saving the handleOnError funciton is called.
     */
    const [isUpdating, saveFormData] = useUpdate(
        lead ? API.POST_CANCEL_LEAD_PROGRAM : API.POST_CANCEL_CLIENT_PROGRAM,
        onSubmitSuccess,
        handleOnError
    );
    const submitCancelation = () => {
        /**
         * This function is called when the form is submitted
         * We send the form data and add the person_id to know what client are we updating
         */
        handleSubmit((data: any) => {
            saveFormData({
                id: person_id,
                proposal_id,
                ...data,
                price: price,
                program_id,
                info
            });
        })();
    };

    const onClickCancel = (e: any) => {
        e.preventDefault();
        handleClosePopup();
    };

    useEffect(() => {
        // register("reason", { required: true });
    }, []);

    return (
        <div
            className="fixed bg-gray-200/70 z-[99] inset-0 flex items-center justify-center bground"
            onClick={function (e) {
                const target = e.target as HTMLDivElement;
                if (target.classList.contains("bground")) {
                    handleClosePopup();
                }
            }}
        >
            <div className="flex flex-col gap-6 items-center bg-white w-[400px] p-9 rounded-lg shadow-md relative">
                <form className="w-full">
                    <CloseIcon
                        className="fill-current text-reyna-text-2 absolute right-0 top-0 mr-1 mt-1 cursor-pointer"
                        onClick={() => handleClosePopup()}
                    />
                    <div className="w-full">
                        <p className="font-inter font-bold text-xl text-center mb-5">
                            Are you sure you want to cancel the program?
                        </p>
                        {/* <DropdownDynamicList
                            title={"Reason"}
                            editing
                            name={"Rejected Reason"}
                            className="flex-col"
                            outerClass={"w-full"}
                            titleClassName="text-r-medium-small-2 pb-2"
                            row={false}
                            onChangeFunction={(val) => setValue("reason" as any, val)}
                            placeholder={"Reason"}
                            search={"Rejected Reason"}
                            values={options.reason}
                        >
                            {getErrorType("reason") === "required" && (
                                <ErrorText msg={FORM_MSG.REQUIRED} />
                            )}
                        </DropdownDynamicList> */}
                        {/* <div className="mt-3">
                            <p className="text-sm">Cancel Notes</p>
                            <textarea
                                className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                                        focus:border-none transition-all rounded-[7px] py-1 px-2 mb-0 h-20`}
                                {...register("notes", {})}
                            />
                        </div> */}
                        <div className="flex gap-4 mt-5 w-full">
                            <button
                                className={`button-crm w-full flex justify-center items-center !bg-reyna-red text-white text-sm font-medium py-2 px-6`}
                                onClick={handleSubmit(submitCancelation)}
                            >
                                {isUpdating ? (
                                    <Loader
                                        type="ThreeDots"
                                        color="#F9D949"
                                        height={20}
                                        width={20}
                                    />
                                ) : (
                                    "Yes"
                                )}
                            </button>
                            <button
                                className="button-crm w-full !bg-reyna-text-2 text-white text-sm font-medium py-2 px-6"
                                onClick={onClickCancel}
                            >
                                {"No"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
