/**
 * Libraries import
 */
import { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
/**
 * Constants import
 */
import { API, PERMISSIONS, ROUTES } from "../config";
/**
 * Pages/Views/Screen Imports
 */
import { useResetRecoilState } from "recoil";
import {
    bulkConversationsSearchOptions,
    campaignsSearchOptions,
    clientsSearchOptions,
    employeesSearchOptions,
    leadsSearchOptions,
    usersSearchOptions,
} from "../config/recoil/atoms";
import { usePermissions } from "../hooks";
import { NotFound } from "../views/NotFound";


const LeadsScreen = lazy(() => import("../views/MainScreens/LeadsScreen"));
const LeadsDetailScreen = lazy(() => import("../views/MainScreens/LeadsDetailScreen"));
const SingleLead = lazy(() => import("../views/Single/SingleLead"));
const CreateNewLead = lazy(() => import("../views/Create/CreateGeneric"));

const ClientsScreen = lazy(() => import("../views/MainScreens/ClientsScreen"));
const ClientsDetailScreen = lazy(() => import("../views/MainScreens/ClientsDetailScreen"));
const ActivitySupervision = lazy(() => import("../views/MainScreens/ActivitySupervision"));
const ProductionAudit = lazy(() => import("../views/MainScreens/ProductionAudit"));
const SingleClient = lazy(() => import("../views/Single/SingleClient"));
const CreateNewClient = lazy(() => import("../views/Create/CreateGeneric"));

const EmployeeScreen = lazy(() => import("../views/MainScreens/EmployeeScreen"));
const CreateNewEmployee = lazy(() => import("../views/Create/CreateEmployee"));
const SingleEmployee = lazy(() => import("../views/Single/SingleEmployee"));

const CampaignsScreen = lazy(() => import("../views/MainScreens/Campaigns/CampaignsScreen"));
const SettingsScreen = lazy(() => import("../views/MainScreens/SettingsScreen"));

const DashboardScreen = lazy(() => import("../views/MainScreens/DashboardScreen"));

const ActivityScreen = lazy(() => import("../views/MainScreens/ActivityScreen"));
const SingleActivity = lazy(() => import("../views/Single/SingleActivity"));

const UsersScreen = lazy(() => import("../views/MainScreens/UsersScreen"));
const CreateUser = lazy(() => import("../views/Create/CreateUser"));
const SingleUser = lazy(() => import("../views/Single/SingleUser"));

const EditProfileScreen = lazy(() => import("../views/MainScreens/UserScreens/EditProfileScreen"));

const CalendarScreen = lazy(() => import("../views/MainScreens/CalendarScreen"));
const SingleCampaign = lazy(() => import("../views/Single/SingleCampaign"));

const TemplatesScreen = lazy(() => import("../views/MainScreens/Campaigns/TemplatesScreen"));
const SingleTemplate = lazy(() => import("../views/Single/SingleTemplate"));
const CreateTemplate = lazy(() => import("../views/Create/CreateTemplate"));

const BulkConversationScreen = lazy(
    () => import("../views/MainScreens/Campaigns/BulkConversationsScreen")
);
const SingleBulkConversation = lazy(() => import("../views/Single/SingleBulkConversation"));

/**
 * This file lists all the routes within this SPA.
 */

export const ApplicationRoutes = () => {
    const { checkPermission } = usePermissions();

    const location = useLocation();
    const resetClientOptions = useResetRecoilState(clientsSearchOptions);
    const resetLeadOptions = useResetRecoilState(leadsSearchOptions);
    const resetEmployeeOptions = useResetRecoilState(employeesSearchOptions);
    const resetCampaignOptions = useResetRecoilState(campaignsSearchOptions);
    const resetuserOptions = useResetRecoilState(usersSearchOptions);
    const resetBulkConversationOptions = useResetRecoilState(bulkConversationsSearchOptions);

    useEffect(() => {
        // If the current route in not a clients page, not starting with /client(s),
        // forget the saved search options.
        if (
            !location.pathname.startsWith(ROUTES.CLIENTS) &&
            !location.pathname.startsWith("/appointment")
        )
            resetClientOptions();
        // If the current route in not a leads page, not starting with /lead(s),
        // forget the saved search options.
        if (
            !location.pathname.startsWith(ROUTES.LEADS) &&
            !location.pathname.startsWith("/appointment")
        )
            resetLeadOptions();
        // Same for employees.
        if (!location.pathname.startsWith(ROUTES.EMPLOYEES)) resetEmployeeOptions();
        // The trick doesn't work as well because the base route has 's'
        // while the other don't.
        if (!location.pathname.startsWith("/campaign")) resetCampaignOptions();
        if (!location.pathname.startsWith("/campaigns/bulk-message"))
            resetBulkConversationOptions();
        if (!location.pathname.startsWith("/user")) resetuserOptions();
    }, [location]);

    return (
        <Suspense
            fallback={
                <div className="lds-ring absolute inset-1/2 transform translate-x-[-50%] translate-y-[-50%] loading-fade-in">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
        >
            <Switch>
                {/* The homapage in this app is /dashboard, so when user go to '/' redirect them to
                the dashboard page */}
                <Route exact path={ROUTES.HOME}>
                    <Redirect to={ROUTES.DASHBOARD} from={ROUTES.HOME} />
                </Route>
                {/* TO be defined*/}
                {checkPermission(PERMISSIONS.SETTINGS_ACCESS) && (
                    <Route path={ROUTES.SETTINGS} render={() => <SettingsScreen />} />
                )}
                {/* This route renders the home page */}
                <Route exact path={ROUTES.DASHBOARD} render={() => <DashboardScreen />} />
                {/* This route renders the edit porfile page */}
                <Route exact path={ROUTES.USER_PROFILE} render={() => <EditProfileScreen />} />
                {/* This route renders templates page */}
                {checkPermission(PERMISSIONS.CAMPAIGN_READ_TEMPLATES) && (
                    <Route path={ROUTES.TEMPLATES}>
                        <TemplatesScreen />
                    </Route>
                )}
                {/*  */}
                {checkPermission(PERMISSIONS.CAMPAIGN_CREATE_TEMPLATES) && (
                    <Route path={ROUTES.NEW_TEMPLATE}>
                        <CreateTemplate />
                    </Route>
                )}
                {/* This route templates single template */}
                {checkPermission(PERMISSIONS.CAMPAIGN_READ_TEMPLATES) && (
                    <Route path={ROUTES.SINGLE_TEMPLATE}>
                        <SingleTemplate />
                    </Route>
                )}
                {/* This route renders bulk conversation page */}
                {checkPermission(PERMISSIONS.CAMPAIGN_READ_BULK_MESSAGES) && (
                    <Route
                        exact
                        path={ROUTES.BULK_CONVERSATION}
                        render={() => <BulkConversationScreen />}
                    />
                )}
                {/* This route renders single bulk conversation page */}
                {checkPermission(PERMISSIONS.CAMPAIGN_READ_BULK_MESSAGES) && (
                    <Route path={ROUTES.SINGLE_BULK_CONVERSATION}>
                        <SingleBulkConversation />
                    </Route>
                )}
                {/* This route renders campaigns page*/}
                {checkPermission(PERMISSIONS.CAMPAIGN_ACCESS) && (
                    <Route path={ROUTES.CAMPAIGNS} render={() => <CampaignsScreen />} />
                )}
                {/* This route renders single campaign */}
                {checkPermission(PERMISSIONS.CAMPAIGN_READ) && (
                    <Route path={ROUTES.SINGLE_CAMPAIGN}>
                        <SingleCampaign />
                    </Route>
                )}
                {/* This route renders single activity page */}
                {checkPermission(PERMISSIONS.ACTIVITY_ACCESS) && (
                    <Route exact path={ROUTES.SINGLE_ACTIVITY}>
                        <SingleActivity />
                    </Route>
                )}
                {/* This route renders the list of all activities */}
                {checkPermission(PERMISSIONS.ACTIVITY_ACCESS) && (
                    <Route path={ROUTES.ACTIVITY} render={() => <ActivityScreen />} />
                )}
                {/* This route renders the list of all users */}
                {checkPermission(PERMISSIONS.USER_ACCESS) && (
                    <Route exact path={ROUTES.USERS} render={() => <UsersScreen />} />
                )}
                {/* This route renders the form to create a new user */}
                {checkPermission(PERMISSIONS.USER_CREATE) && (
                    <Route path={ROUTES.NEW_SINGLE_USERS}>
                        <CreateUser />
                    </Route>
                )}
                {checkPermission(PERMISSIONS.USER_READ) && (
                    <Route path={ROUTES.SINGLE_USERS}>
                        <SingleUser />
                    </Route>
                )}

                {/* This route renders the list of all leads */}
                {checkPermission(PERMISSIONS.LEAD_ACCESS) && (
                    <Route exact path={ROUTES.LEADS} render={() => <LeadsScreen />} />
                )}
                {checkPermission(PERMISSIONS.LEAD_DETAILS) && (
                    <Route path={ROUTES.LEADS_DETAILS} render={() => <LeadsDetailScreen />} />
                )}
                {checkPermission(PERMISSIONS.LEAD_ACCESS) && (
                    <Route path={ROUTES.SINGLE_LEADS} render={() => <SingleLead />} />
                )}

                {/* The route renders a single lead in detail */}
                {/* 
                    The route for a single lead in detail is inside leads mainscreen 
                    because it needs some parametres from the table filtering
                */}
                {/* This route renders a form to create a new lead */}
                {checkPermission(PERMISSIONS.LEAD_CREATE) && (
                    <Route
                        path={ROUTES.NEW_SINGLE_LEADS}
                        render={() => (
                            <CreateNewLead
                                fetchUrl={API.CREATE_LEAD}
                                redirectUrl={ROUTES.SINGLE_LEADS}
                                response_key="lead"
                            />
                        )}
                    />
                )}
                {/* This route renders the list of all client */}

                {checkPermission(PERMISSIONS.CLIENT_ACCESS) && (
                    <Route exact path={ROUTES.CLIENTS} render={() => <ClientsScreen />} />
                )}
                {checkPermission(PERMISSIONS.CLIENT_DETAILS) && (
                    <Route path={ROUTES.CLIENTS_DETAILS} render={() => <ClientsDetailScreen />} />
                )}
                {checkPermission(PERMISSIONS.CLIENT_ACTIVITY) && (
                    <Route path={ROUTES.CLIENTS_ACTIVITY} render={() => <ActivitySupervision />} />
                )}
                {checkPermission(PERMISSIONS.CLIENT_PRODUCTION) && (
                    <Route path={ROUTES.CLIENTS_PRODUCTION} render={() => <ProductionAudit />} />
                )}
                {checkPermission(PERMISSIONS.CLIENT_ACCESS) && (
                    <Route path={ROUTES.SINGLE_CLIENTS} render={() => <SingleClient />} />
                )}
                {/* 
                    The route for a single client in detail is inside clients mainscreen 
                    because it needs some parametres from the table filtering
                */}
                {/* This route renders a form to create a new client */}
                {checkPermission(PERMISSIONS.CLIENT_CREATE) && (
                    <Route
                        path={ROUTES.NEW_SINGLE_CLIENTS}
                        render={() => (
                            <CreateNewClient
                                fetchUrl={API.CREATE_CLIENT}
                                redirectUrl={ROUTES.SINGLE_CLIENTS}
                                response_key="client"
                            />
                        )}
                    />
                )}

                {checkPermission(PERMISSIONS.EMPLOYEE_ACCESS) && (
                    <Route exact path={ROUTES.EMPLOYEES} render={() => <EmployeeScreen />} />
                )}
                {checkPermission(PERMISSIONS.EMPLOYEE_CREATE) && (
                    <Route
                        exact
                        path={ROUTES.NEW_SINGLE_EMPLOYEES}
                        render={() => <CreateNewEmployee />}
                    />
                )}
                {checkPermission(PERMISSIONS.EMPLOYEE_ACCESS) && (
                    <Route path={ROUTES.SINGLE_EMPLOYEES} render={() => <SingleEmployee />} />
                )}
                {/* This route renders the calender view */}
                {checkPermission(PERMISSIONS.CALENDAR_ACCESS) && (
                    <Route exact path={ROUTES.CALENDAR} render={() => <CalendarScreen />} />
                )}
                {/* This route renders the not found view. The route need to be the last one*/}
                <Route path={ROUTES.NOT_FOUND} render={() => <NotFound />} />
            </Switch>
        </Suspense>
    );
};
