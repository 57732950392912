import { PersonAlgolia } from "./Autocomplete";
import { AtIcon, HashIcon, LeadsLogo, MarriedIcon, PhoneIcon, Pin2Icon, UsersLogo } from "../../icons";
import React from "react";
import { AutocompleteComponents } from "@algolia/autocomplete-js";
import moment from "moment";
interface iTypeIcon {
    type: string | undefined;
}

const TypeIcon = ({ type }: iTypeIcon) => {
    if (!type) return <></>;
    const iconClass = 'fill-current w-[12px] h-[20px]';
    return (
        <div className="ml-2 flex flex-row h-auto w-auto bg-reyna-primary text-r-small items-center text-white px-2 rounded-md gap-1">
            {type === 'credit_lead' ? (
                <>
                    <LeadsLogo className={iconClass} />
                    <span>Lead</span>
                </>
            ) : (
                <>
                    <UsersLogo className={iconClass} />
                    <span>Client</span>
                </>
            )}
        </div>
    )
}

export function AutocompletePersonItem({ personItem, components }: Iprops) {
    return (
        <div className="aa-ItemContent font-inter">
            <div className="flex justify-between">
                <div className="">
                    <div className="aa-ItemContentTitle text-r-medium-small-2 items-center flex">
                        <div className="">
                            <components.Highlight hit={personItem} attribute={["first_name"]} />
                            &nbsp;
                            <components.Highlight hit={personItem} attribute={["last_name"]} />
                        </div>
                        <TypeIcon type={personItem.type} />
                    </div>
                    <div
                        className="aa-ItemContentDescription text-r-small md:flex items-center md:space-x-4 h-[30px]">
                        {personItem.email &&
                            <div className="flex text-reyna-primary items-start">
                                <div className="flex items-center">
                                    <AtIcon className="fill-current w-[13px]" />
                                    <div className="ml-1 inline-block align-middle">
                                        <components.Highlight hit={personItem} attribute={["email"]} />
                                    </div>
                                </div>
                            </div>
                        }
                        {personItem.address &&
                            <div className="flex text-reyna-primary items-start">
                                <div className="flex items-center">
                                    <Pin2Icon className="fill-current w-[13px]" />
                                    <span className="ml-1">
                                        <components.Highlight hit={personItem} attribute={["address"]} />
                                    </span>
                                </div>
                            </div>
                        }
                        {(personItem.spouse_first_name || personItem.spouse_last_name) &&
                            <div className="flex text-reyna-primary items-start">
                                <div className="flex items-center">
                                    <MarriedIcon className="fill-current w-[13px]" />
                                    <div className="ml-1">
                                        <components.Highlight hit={personItem} attribute={["spouse_first_name"]} />
                                        &nbsp;
                                        <components.Highlight hit={personItem} attribute={["spouse_last_name"]} />
                                    </div>
                                </div>
                            </div>
                        }
                        {personItem.phone_display &&
                            <div className="flex shrink-0 text-reyna-primary items-start">
                                <div className="flex items-center">
                                    <PhoneIcon className="fill-current w-[13px]" />
                                    <div className="ml-1">
                                        <components.Highlight hit={personItem} attribute={["phone_display"]} />
                                    </div>
                                </div>
                            </div>
                        }
                        {personItem.social_security_number &&
                            <div className="flex shrink-0 text-reyna-primary items-start">
                                <div className="flex items-center">
                                    <HashIcon className="fill-current w-[13px]" />
                                    <div className="ml-1">
                                        <components.Highlight hit={personItem} attribute={["social_security_number_display"]} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="text-reyna-primary text-r-small-medium">
                    {personItem.created_at &&
                        <p>{moment(personItem.created_at).format("DD/MM/YYYY")}</p>
                    }
                </div>
            </div>
        </div>
    );
}

interface Iprops {
    personItem: PersonAlgolia;
    components: AutocompleteComponents;
}