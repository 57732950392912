import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: any) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    textBoxClass?: string;
    value?: any;
    selectOptions: any[];
    isObject?: boolean;
    objectKeyValue?: string;
    objectKeyDisplay?: string;
    showBlankOption?: boolean;
    children?: JSX.Element | false | undefined;
    isMulti?: boolean;
    isClearable?: boolean;
    selectStyles?: any;
    control?: any;
    textBlack?: boolean;
    readonly?: boolean;
    handleExclude?: () => void;
    valueExclude?: boolean;
    menuPosition?: "fixed";
    searchable?: boolean;
    onSearch?: (val: string, e?: any) => void;
    loading?: boolean;
};

export const DropDownGeneric = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName = "",
    titleClassname,
    editing,
    textBoxClass,
    value = "",
    selectOptions,
    isObject = false,
    objectKeyValue = "",
    objectKeyDisplay = "",
    showBlankOption = true,
    isMulti = false,
    children,
    isClearable = true,
    selectStyles,
    control,
    textBlack = false,
    readonly = false,
    handleExclude,
    valueExclude,
    menuPosition,
    searchable,
    onSearch,
    loading,
}: InputProps) => {
    const onChange = (val: any, action: any) => {
        if (action.action === "clear") {
            isMulti && onChangeFunction && onChangeFunction([]);
            !isMulti && onChangeFunction && onChangeFunction("");
        } else if (action.action === "remove-value") {
            isMulti && onChangeFunction && onChangeFunction(val);
        } else if (action.action === "select-option") {
            if (onChangeFunction) {
                isMulti ? onChangeFunction(val) : onChangeFunction(val.value);
            }
        } else {
            onChangeFunction && onChangeFunction(val.value);
        }
    };

    const toLabelValue = () => {
        return selectOptions.map((o) => ({
            value: isObject ? o[objectKeyValue] : o,
            label: isObject ? o[objectKeyDisplay] : o,
        }));
    };

    const getValue = () => {
        if (!selectOptions) return "";
        if (!isObject) return value;
        if (isObject) {
            const labelObject = selectOptions.find((s) => s[objectKeyValue] === value);
            return {
                value: value,
                label: labelObject ? labelObject[objectKeyDisplay] : null,
            };
        }
    };
    return (
        <div className={`${className} flex flex-col w-full"`}>
          <div className={`flex flex-col justify-start items-start gap-y-2 w-full`}>
            <CustomSelect
                name={name}
                title={title}
                menuPosition={menuPosition}
                inputClassname={inputClassName}
                editing={editing}
                isClearable={isClearable}
                isObject={isObject}
                containerClassname="capitalize"
                className={className ? className + ' w-full' : "flex flex-col mb-4 w-full"}
                titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2"}
                options={toLabelValue()}
                value={getValue()}
                selectStyles={selectStyles}
                placeholder={placeholder}
                onChangeFunction={onChange}
                control={control}
                textBlack={textBlack}
                multi={isMulti}
                readonly={readonly}
                searchable={searchable}
                onSearch={onSearch}
                loading={loading}
            />
            {valueExclude !== undefined && handleExclude &&
                <div>
                    <label className="flex items-center gap-2 font-inter text-reyna-primary text-r-medium-small">
                        <input
                            className={`outline-none text-reyna-primary  ${!editing ? " bg-reyna-grey-3" : " bg-white"
                                }  !border-reyna-grey-1 !rounded-md focus:ring-reyna-primary focus:border-reyna-primary transition-all`}
                            type="checkbox"
                            onChange={() => {
                                handleExclude();
                            }}
                            disabled={false}
                            value={name + '_exclude'}
                            checked={valueExclude}
                        />
                        Exclude
                    </label>
                </div>
            }
          </div>
          {children && children}
        </div>

    );
};
