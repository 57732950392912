import { useEffect, useMemo, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import { DropDownGeneric } from "../../../components/dropdowns";
import { DropDownCreditors } from "../../../components/dropdowns/DropDownCreditors";
import { DropdownDynamicList } from "../../../components/dropdowns/DropDownDynamicList";
import { ErrorText } from "../../../components/forms/ErrorText";
import { AccountNumbers } from "../../../components/inputs/AccountNumbers";
import { CheckboxRFH } from "../../../components/inputs/CheckboxRFH";
import Input from "../../../components/inputs/Input";
import { InputRFH } from "../../../components/inputs/InputRFH";
import { NumberFormatRFH } from "../../../components/inputs/NumberFormatRFH";
import { SelectCreditReport } from "../../../components/inputs/SelectCreditReport";
import { API, API_BASEURL, FORM_MSG, REGEX_TEST, SWR_CONFIG, buildUrl } from "../../../config";
import {
    calculateProgramPrice,
    getFieldsByProgram,
    getProgramMaxPrice,
    isClubFico,
} from "../../../helpers/Programs";
import { formHandleEmpty } from "../../../helpers/forms";
import useGetSWR from "../../../hooks/useGetSWR";
import { CloseIcon } from "../../../icons";
import { CrPricingRes } from "../../../types";
import { CreateProgramPopup } from "../../../types/ClientProgram";
import { NumberFormatInput } from "../../../components/inputs/NumberFormatInput";

/**
 * If validating false --> add new program popup comming from popup tab
 * If validating true // addProgram false --> validating program by Juan
 * If validating and addProgram true --> validate price by agent and add program
 */
interface ValidateProgramPopupParams {
    person_id: string;
    clickFunctionCancelButton?: () => void;
    program: string;
    backFunction?: () => void;
    cancelFunction?: () => void;
    onSubmit?: () => void;
    onCloseFunction?: () => void;
    isUpdating: boolean;
    validating?: boolean;
    addProgram?: boolean;
    showInfo?: boolean;
    proposal?: boolean;
    priceError?: string;
    setPriceError?: (val: string) => void;
    lead?: boolean;
}

export const ValidateProgramPopup = ({
    proposal,
    clickFunctionCancelButton,
    person_id,
    program,
    addProgram,
    backFunction,
    onSubmit,
    onCloseFunction,
    cancelFunction,
    isUpdating,
    validating,
    showInfo,
    setPriceError,
    priceError,
    lead = false,
}: ValidateProgramPopupParams) => {
    const isFinalValidation = useMemo(() => addProgram && validating, [addProgram, validating])
    const isValidating = useMemo(() => !showInfo && !proposal && validating, [proposal, showInfo, validating]);

    const [fields, setFields] = useState<any>({});
    const urlCrPricing = buildUrl(API.CR_PRICING, {}, API_BASEURL, {});
    const { data: crPricingData } = useGetSWR<CrPricingRes>(urlCrPricing, SWR_CONFIG.FORMS);


    useEffect(() => {
        setFields(getFieldsByProgram(program));
    }, []);

    const {
        register,
        getValues,
        watch,
        setValue,
        setError,
        clearErrors,
        control,
        formState: { errors },
    } = useFormContext<CreateProgramPopup>();
    const options = watch();
    useEffect(() => {
        if (fields.fields) {
            for (const f of fields.fields) {
                if (showInfo) continue;
                if (f.onlyFinal && !isFinalValidation) continue;
                if ((f as any).automated) {
                    register(f.accesKey as any, { required: f.required });
                    setValue(f.accesKey as any, (f as any).automated({ ...options, isFinalValidation, isValidating }));
                } else if (f.type === "Bureaus" && f.required) {
                    register("ex", {
                        value: false,
                        validate: (val) => {
                            if (!val && !getValues().eq && !getValues().tu)
                                return "* Please select at least one bureau.";
                            else return undefined;
                        },
                    });
                    register("eq", { value: false });
                    register("tu", { value: false });
                    // setError('ex', { type: 'validate', message: '* Please select at least one bureau.' });
                } else if (f.type === "CreditReport") {
                    register("credit_report", { required: true });
                } else if (f.accesKey === "club") {
                    register("club", { required: true });
                } else if (
                    f.type !== "Accounts" &&
                    f.type !== "Collections" &&
                    f.accesKey !== "special_pricing"
                ) {
                    register(f.accesKey, {
                        required: !f.includeInType
                            ? true
                            : f.includeInType.includes(options.club_type),
                    });
                } else if (
                    f.type === "Accounts" &&
                    isClubFico(program) &&
                    options.club_type === "VIP"
                ) {
                    register("accounts_negative", { required: true });
                    register("accounts_positive", {
                        required: true,
                        validate: (val) => {
                            if (Number(val) + Number(getValues().accounts_negative) > 0)
                                return "Accounts must be 0.";
                            return undefined;
                        },
                    });
                } else if (f.type === "Accounts" && program === "VIP Credit") {
                    register("accounts_negative", {
                        validate: (val) => {
                            if (Number(val) === 0) return "At least one account must be negative.";
                            return undefined;
                        },
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, validating, addProgram, options.club_type]);

    useEffect(() => {
        if (!crPricingData) return;
        const price = parseFloat(getValues().price as any);
        let maxPrice = null;
        if (!options.special_pricing)
            maxPrice = getProgramMaxPrice(program, crPricingData!.cr_pricing || 50, {
                ...options,
            } as any);
        if (price) {
            if ((getValues().cost || 0) > price)
                return setPriceError && setPriceError("* Price cannot be lower than cost.");
            else if (price < 0)
                return setPriceError && setPriceError("* Price cannot be negative.");
            else if (maxPrice !== null && price > maxPrice)
                return (
                    setPriceError && setPriceError(`* Price cannot be higher than $${maxPrice}.`)
                );
            else if (price >= (getValues().cost || 0) && price > 0)
                return setPriceError && setPriceError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.price, options.cost, options.special_pricing, crPricingData]);

    useEffect(() => {
        if (fields && crPricingData) {
            if (showInfo || (validating && options.special_pricing)) return;
            const { cost } = calculateProgramPrice(program, crPricingData.cr_pricing, options.payment_option, {
                ...options,
            } as any);
            setValue("cost", cost as number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        options.name,
        options.accounts_positive,
        options.accounts_negative,
        options.tradelines,
        options.bk_type,
        options.cost,
        options.price,
        options.inquiries,
        options.simulations,
        options.records,
        options.ex,
        options.eq,
        options.tu,
        options.state,
        options.club,
        options.payment_option,
        options.amount_approved,
        options.property_value,
        options.club_type,
        crPricingData,
    ]);

    useEffect(() => {
        if (fields.fields) {
            const tradelines = fields.fields.findIndex((f: any) => f.accesKey === "tradelines");
            const price = fields.fields.findIndex((f: any) => f.accesKey === "price");
            if (tradelines > 0 && !isFinalValidation) {
                if (fields.fields[tradelines].automated)
                    setValue(
                        "tradelines",
                        fields.fields[tradelines].automated({ ...options, isFinalValidation, isValidating }) || "0"
                    );
            }
            if (price > 0 && program === "Tradelines Funding") {
                if (fields.fields[price].automated)
                    setValue("price", fields.fields[price].automated({ ...options, isFinalValidation, isValidating }) || "0");
            }
            if (program === "ID Theft") {
                if (Number(options.accounts_negative) + Number(options.accounts_positive) > 10) {
                    setError("accounts_positive", {
                        type: "validate",
                        message: "Max 10 accounts.",
                    });
                } else {
                    clearErrors("accounts_positive");
                }
            }
            if (isClubFico(program) && options.club_type === "VIP") {
                if (Number(options.accounts_negative) + Number(options.accounts_positive) !== 0) {
                    setError("accounts_positive", {
                        type: "validate",
                        message: "Accounts must be 0.",
                    });
                } else {
                    clearErrors("accounts_positive");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.accounts_negative, options.accounts_positive]);

    useEffect(() => {
        if (fields.fields) {
            const monthly = fields.fields.findIndex((f: any) => f.accesKey === "estimated_monthly");
            if (monthly >= 0) {
                setValue(
                    "estimated_monthly",
                    fields.fields[monthly].automated({ ...options, isFinalValidation, isValidating }) || "0"
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.payment_option, options.price, options.estimated_deposit]);

    useEffect(() => {
        if (fields.fields) {
            const price = fields.fields.findIndex((f: any) => f.accesKey === "price");
            if (price > 0 && fields.fields[price].automated) {
                setValue("price", fields.fields[price].automated({ ...options, isFinalValidation, isValidating }) || "0");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.amount_approved]);

    useEffect(() => {
        if (fields.fields) {
            const estimated_deposit = fields.fields.findIndex((f: any) => f.accesKey === "estimated_deposit");
            if (estimated_deposit > 0 && fields.fields[estimated_deposit].automated) {
                setValue("estimated_deposit", fields.fields[estimated_deposit].automated({ ...options }) || "0");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.funding_type]);

    /**
     * For deeply nested object properties, errors have to access
     * via the get method.
     * @param key Property key
     * @returns error object
     */
    const getErrorType = (key: string) => get(errors, key)?.type;

    const handleClosePopup = () => {
        if (clickFunctionCancelButton) clickFunctionCancelButton();
        onCloseFunction && onCloseFunction();
    };

    const goBack = (e: any) => {
        e.preventDefault();
        backFunction && backFunction();
    };

    const changeBureau = (key: "ex" | "eq" | "tu", value: boolean) => {
        const values = {
            ex: options.ex,
            tu: options.tu,
            eq: options.eq,
        };
        values[key] = value;
        // if (!values.ex && !values.eq && !values.tu)
        //     setError('ex', { type: 'validate', message: '* Please select at least one bureau.' });
        // else clearErrors('ex')
        setValue(key, value);
    };

    const changeCreditReport = (key: "credit_report", value: string) => {
        if (!value)
            setError(key, { type: "required", message: "* Please select at least one bureau." });
        else clearErrors(key);
        setValue(key, value);
    };

    const getTitle = () => {
        if (proposal) return "Proposal";
        if (showInfo) return "Info";
        return "Validation";
    };

    return (
        <div
            className={`${validating && "bg-gray-200/70"
                } fixed z-[99] inset-0 flex items-center justify-center bground`}
            onClick={function (e) {
                const target = e.target as HTMLDivElement;
                if (target.classList.contains("bground")) {
                    handleClosePopup();
                }
            }}
        >
            <div
                className={`max-h-[95vh] flex flex-col gap-6 items-center bg-white ${validating && !addProgram
                    ? fields.validateWidth || fields.popupWidth
                    : fields?.popupWidth
                    } p-9 rounded-[2rem] shadow-md relative ${isClubFico(program) && options.club_type !== "Regular"
                        ? "overflow-y-visible"
                        : "overflow-y-auto"
                    }`}
            >
                <div className="w-full">
                    <CloseIcon
                        className="fill-current text-reyna-text-2 absolute right-2 top-2 mr-1 mt-1 cursor-pointer"
                        onClick={() => handleClosePopup()}
                    />
                    <div className="w-full">
                        <p className="font-inter font-bold text-xl">
                            {program} {getTitle()}
                        </p>
                    </div>
                    <div className="grid grid-cols-8 gap-4 py-5 text-r-medium-small-2 items-start">
                        {fields?.fields?.map((f: any) => {
                            const specialPrice =
                                (f.accesKey === "price" || f.accesKey === "cost") &&
                                validating &&
                                !addProgram &&
                                options.special_pricing &&
                                !program.includes("Funding -");
                            const tradelinesValidation =
                                validating && !addProgram && f.accesKey === "tradelines";
                            let isEditable =
                                specialPrice ||
                                (!showInfo &&
                                    (tradelinesValidation ||
                                        (f.editable &&
                                            (!isFinalValidation ||
                                                (isFinalValidation &&
                                                    f.finalValidationEditable)))));
                            if (
                                f.accesKey === "estimated_deposit" &&
                                options.special_pricing &&
                                validating &&
                                !addProgram
                            ) {
                                isEditable = true;
                            }
                            if (f.accesKey === "club" && !options.special_pricing && validating) {
                                isEditable = false;
                            }
                            if (
                                f.includeInType &&
                                isClubFico(program) &&
                                !f.includeInType.includes(options.club_type)
                            )
                                return <></>;
                            const width =
                                validating && !addProgram ? f.validateWidth || f.width : f.width;
                            if (f.validation) {
                                register(f.accesKey, f.validation);
                            }
                            let fieldValidate = validating && !addProgram ? {} : f.fieldValidate;
                           
                            switch (f.type) {
                                case "DynamicDropdown":
                                    return (
                                        <DropdownDynamicList
                                            key={f.title}
                                            title={f.title}
                                            editing={isEditable}
                                            readonly={!isEditable}
                                            name={f.accesKey}
                                            className="flex-col"
                                            outerClass={width}
                                            menuPosition={"fixed"}
                                            titleClassName="text-r-medium-small-2"
                                            row={false}
                                            onChangeFunction={(val) =>
                                                setValue(f.accesKey as any, val)
                                            }
                                            placeholder={f.title}
                                            search={f.search}
                                            values={(options as any)[f.accesKey]}
                                        >
                                            {getErrorType(f.accesKey) === "required" && (
                                                <ErrorText msg={FORM_MSG.REQUIRED} />
                                            )}
                                        </DropdownDynamicList>
                                    );
                                case "Accounts":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`flex flex-col w-full ${width}`}
                                        >
                                            <div className="flex flex-row w-full">
                                                <div className="flex flex-col items-start w-1/2 ">
                                                    <span className="ml-1 mr-4 sm:mr-0">Accounts + </span>
                                                    <InputRFH
                                                        className="flex-col w-full pr-2"
                                                        name="accounts_positive"
                                                        inputClassName="!mb-0"
                                                        editing={isEditable}
                                                        inputRegister={register}
                                                        validation={{
                                                            pattern: REGEX_TEST.NUMBER,
                                                            ...formHandleEmpty,
                                                            required: f.required,
                                                        }}
                                                    >
                                                        <>
                                                            {getErrorType("accounts_positive") ===
                                                                "pattern" && (
                                                                    <ErrorText
                                                                        msg={FORM_MSG.INVALID_NUMBER}
                                                                    />
                                                                )}
                                                            {getErrorType("accounts_positive") ===
                                                                "required" && (
                                                                    <ErrorText
                                                                        msg={FORM_MSG.REQUIRED}
                                                                    />
                                                                )}
                                                        </>
                                                    </InputRFH>
                                                </div>
                                                <div className="flex flex-col items-start w-1/2">
                                                    <span className="ml-1 mr-4 sm:mr-0 pl-2">
                                                        Accounts -
                                                    </span>
                                                    <InputRFH
                                                        className="flex-col w-full pl-2"
                                                        inputClassName="!mb-0"
                                                        name="accounts_negative"
                                                        editing={isEditable}
                                                        inputRegister={register}
                                                        validation={{
                                                            pattern: REGEX_TEST.NUMBER,
                                                            ...formHandleEmpty,
                                                            required: f.required,
                                                        }}
                                                    >
                                                        <>
                                                            {getErrorType("accounts_negative") ===
                                                                "pattern" && (
                                                                    <ErrorText
                                                                        msg={FORM_MSG.INVALID_NUMBER}
                                                                    />
                                                                )}
                                                            {getErrorType("accounts_negative") ===
                                                                "required" && (
                                                                    <ErrorText
                                                                        msg={FORM_MSG.REQUIRED}
                                                                    />
                                                                )}
                                                        </>
                                                    </InputRFH>
                                                </div>
                                            </div>
                                            {getErrorType("accounts_positive") === "validate" && (
                                                <ErrorText
                                                    msg={errors.accounts_positive?.message || ""}
                                                />
                                            )}
                                            {getErrorType("accounts_negative") === "validate" && (
                                                <ErrorText
                                                    msg={errors.accounts_negative?.message || ""}
                                                />
                                            )}
                                        </div>
                                    );
                                case "Number":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`${width} flex sm:flex-col items-center sm:items-start`}
                                        >
                                            <span className="text-r-medium-small-2">
                                                {f.title}
                                            </span>
                                            <InputRFH
                                                editing={isEditable}
                                                className="flex-col w-full"
                                                inputClassName="!mb-0"
                                                name={f.accesKey}
                                                type={f.typeInput || undefined}
                                                inputRegister={register}
                                                validation={{
                                                    pattern: REGEX_TEST.NUMBER,
                                                    ...formHandleEmpty,
                                                    required: f.required,
                                                    validate: fieldValidate?.validate,
                                                }}
                                            >
                                                <>
                                                    {getErrorType(f.accesKey) === "pattern" && (
                                                        <ErrorText msg={FORM_MSG.INVALID_NUMBER} />
                                                    )}
                                                    {getErrorType(f.accesKey) === "required" && (
                                                        <ErrorText msg={FORM_MSG.REQUIRED} />
                                                    )}
                                                    {fieldValidate &&
                                                        getErrorType(f.accesKey) === "validate" && (
                                                            <ErrorText
                                                                msg={fieldValidate.message}
                                                            />
                                                        )}
                                                </>
                                            </InputRFH>
                                        </div>
                                    );
                                case "Pay Day":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`${width} flex sm:flex-col items-center sm:items-start`}
                                        >
                                            <span className="text-r-medium-small-2">
                                                {f.title}
                                            </span>
                                            <InputRFH
                                                editing={isEditable}
                                                className="flex-col w-full"
                                                inputClassName="!mb-0"
                                                name={f.accesKey}
                                                type={f.typeInput || undefined}
                                                inputRegister={register}
                                                validation={{
                                                    pattern: REGEX_TEST.NUMBER,
                                                    ...formHandleEmpty,
                                                    required: f.required,
                                                    validate: !showInfo && f.fieldValidate?.validate,
                                                }}
                                            >
                                                <>
                                                    {getErrorType(f.accesKey) === "pattern" && (
                                                        <ErrorText msg={FORM_MSG.INVALID_NUMBER} />
                                                    )}
                                                    {getErrorType(f.accesKey) === "required" && (
                                                        <ErrorText msg={FORM_MSG.REQUIRED} />
                                                    )}
                                                    {
                                                        getErrorType(f.accesKey) === "validate" && (
                                                            <ErrorText
                                                                msg={f.fieldValidate.message}
                                                            />
                                                        )}
                                                </>
                                            </InputRFH>
                                        </div>
                                    );
                                case "Money":
                                    if (f.accesKey === "price") {
                                        return (
                                            <>
                                                <NumberFormatRFH
                                                    key={f.title}
                                                    editing={isEditable}
                                                    title={f.title}
                                                    name={f.accesKey}
                                                    inputClassName="!mb-0"
                                                    className={`flex-col ${width}`}
                                                    prefix={"$"}
                                                    inputRegister={{ ...register }}
                                                    suffix={""}
                                                    validation={{ required: false }}
                                                >
                                                    <>
                                                        {priceError && <ErrorText msg={priceError} />}
                                                        {validating && options.payment_option === 0 && (
                                                            <p className="text-xs text-green-600">
                                                                Paid in full 10% discount
                                                            </p>
                                                        )}
                                                    </>
                                                </NumberFormatRFH>
                                                {options.payment_option === 0 && !showInfo && !validating && (
                                                    <>
                                                        <NumberFormatInput
                                                            key={"Paid in full cost"}
                                                            editing={false}
                                                            title={"Paid in full cost"}
                                                            name={f.accesKey}
                                                            inputClassName="!mb-0 !bg-input-green"
                                                            className={`flex-col ${width}`}
                                                            prefix={"$"}
                                                            suffix={""}
                                                            value={options?.cost ? options?.cost * 0.9 : options.cost}
                                                        >
                                                            <>{priceError && <ErrorText msg={priceError} />}</>
                                                        </NumberFormatInput>
                                                        <NumberFormatInput
                                                            key={"Paid in full price"}
                                                            editing={false}
                                                            title={"Paid in full price"}
                                                            name={f.accesKey}
                                                            inputClassName="!mb-0 !bg-input-green"
                                                            className={`flex-col ${width}`}
                                                            prefix={"$"}
                                                            suffix={""}
                                                            value={options?.price ? options?.price * 0.9 : options.price}
                                                        >
                                                            <>{priceError && <ErrorText msg={priceError} />}</>
                                                        </NumberFormatInput>
                                                    </>
                                                )}
                                            </>

                                        );

                                    }
                                    if (f.accesKey === "cost") {
                                        return (
                                            <div className={`flex flex-col w-full ${width}`}>
                                                <NumberFormatRFH
                                                    key={f.title}
                                                    editing={isEditable}
                                                    title={f.title}
                                                    name={f.accesKey}
                                                    inputClassName="!mb-0"
                                                    className={`flex-col ${width}`}
                                                    prefix={"$"}
                                                    inputRegister={{ ...register }}
                                                    suffix={""}
                                                    validation={{ required: false }}
                                                >
                                                    {validating && options.payment_option === 0 && (
                                                        <p className="text-xs text-green-600">
                                                            Paid in full 10% discount
                                                        </p>
                                                    )}
                                                </NumberFormatRFH>
                                            </div>
                                        );
                                    }
                                    return (
                                        <NumberFormatRFH
                                            key={f.title}
                                            editing={isEditable}
                                            title={f.title}
                                            name={f.accesKey}
                                            inputClassName="!mb-0"
                                            className={`flex-col ${width}`}
                                            control={control}
                                            prefix={"$"}
                                            suffix={""}
                                            inputRegister={{ ...register }}
                                            validation={{
                                                required: f.required,
                                                validate: fieldValidate?.validate,
                                            }}
                                        >
                                            <>
                                                {getErrorType(f.accesKey) === "required" && (
                                                    <ErrorText msg={FORM_MSG.REQUIRED} />
                                                )}
                                                {getErrorType(f.accesKey) === "validate" && (
                                                    <ErrorText
                                                        msg={(errors as any)[f.accesKey].message}
                                                    />
                                                )}
                                                {fieldValidate &&
                                                    getErrorType(f.accesKey) === "validate" && (
                                                        <ErrorText msg={fieldValidate.message} />
                                                    )}
                                            </>
                                        </NumberFormatRFH>
                                    );
                                case "Checkbox":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`${width} h-full flex sm:flex-col items-center justify-center pt-5`}
                                        >
                                            <CheckboxRFH
                                                editing={isEditable && !validating}
                                                className="flex-col w-full"
                                                titleClassname=""
                                                title={f.title}
                                                name={f.accesKey}
                                                inputRegister={register}
                                            />
                                            {getErrorType(f.accesKey) === "required" && (
                                                <ErrorText msg={FORM_MSG.REQUIRED} />
                                            )}
                                        </div>
                                    );
                                case "Dropdown":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`${width} w-full flex sm:flex-col items-center sm:items-start`}
                                        >
                                            <DropDownGeneric
                                                title={f.title}
                                                inputClassName="w-full"
                                                editing={isEditable}
                                                readonly={!isEditable}
                                                className="w-full"
                                                value={(options as any)[f.accesKey]}
                                                name="legal_status"
                                                onChangeFunction={(val) =>
                                                    setValue(f.accesKey as any, val, {
                                                        shouldValidate: true,
                                                    })
                                                }
                                                selectOptions={(f as any).options}
                                                isObject={f.isObject}
                                                objectKeyValue={f.objectKeyValue}
                                                objectKeyDisplay={f.objectKeyDisplay}
                                                menuPosition="fixed"
                                            />
                                            {getErrorType(f.accesKey) === "required" && (
                                                <ErrorText msg={FORM_MSG.REQUIRED} />
                                            )}
                                            {getErrorType(f.accesKey) === "validate" && (
                                                <ErrorText msg={f.validationMessage} />
                                            )}
                                        </div>
                                    );
                                case "Input":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`${width} flex sm:flex-col items-center sm:items-start`}
                                        >
                                            <span className="mb-2">{f.title}</span>
                                            <InputRFH
                                                editing={isEditable}
                                                className="flex-col w-full"
                                                titleClassname=""
                                                name={f.accesKey}
                                                inputRegister={register}
                                                validation={{
                                                    ...formHandleEmpty,
                                                    required: f.required,
                                                }}
                                            />
                                            {getErrorType(f.accesKey) === "required" && (
                                                <ErrorText msg={FORM_MSG.INVALID_NUMBER} />
                                            )}
                                        </div>
                                    );
                                case "Bureaus":
                                    return (
                                        <div
                                            key={f.title}
                                            className={`${width} flex sm:flex-col items-center sm:items-start`}
                                        >
                                            <span className="mb-2">{f.title}</span>
                                            <div className="flex flex-row gap-x-4 items-center h-full">
                                                <Input
                                                    editing={isEditable}
                                                    type="checkbox"
                                                    checkboxNotClickable={false}
                                                    title="EQ"
                                                    className={` md:col-span-5 flex-row-reverse gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                                                    inputClassName={`p-3 custom_checkbox`}
                                                    value={"certified"}
                                                    titleClassname=""
                                                    isChecked={options.eq}
                                                    onChangeFunction={() =>
                                                        changeBureau("eq", !options.eq)
                                                    }
                                                />
                                                <Input
                                                    editing={isEditable}
                                                    type="checkbox"
                                                    checkboxNotClickable={false}
                                                    title="TU"
                                                    className={` md:col-span-5 flex-row-reverse gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                                                    inputClassName={`p-3 custom_checkbox`}
                                                    value={"certified"}
                                                    titleClassname=""
                                                    isChecked={options.tu}
                                                    onChangeFunction={() =>
                                                        changeBureau("tu", !options.tu)
                                                    }
                                                />
                                                <Input
                                                    editing={isEditable}
                                                    type="checkbox"
                                                    checkboxNotClickable={false}
                                                    title="EX"
                                                    className={`md:col-span-5 flex-row-reverse gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                                                    inputClassName={`p-3 custom_checkbox`}
                                                    value={"certified"}
                                                    titleClassname=""
                                                    isChecked={options.ex}
                                                    onChangeFunction={() =>
                                                        changeBureau("ex", !options.ex)
                                                    }
                                                />
                                            </div>
                                            {getErrorType("ex") === "validate" && (
                                                <ErrorText msg={errors.ex?.message || ""} />
                                            )}
                                        </div>
                                    );
                                case "AccountNumbers":
                                    return (
                                        <>
                                            <AccountNumbers
                                                title={f.title}
                                                isEditable={isEditable}
                                                width={width}
                                                control={control}
                                            />
                                            {get(errors, "account_numbers")?.root?.type ===
                                                "required" && (
                                                    <div className="w-full col-span-8">
                                                        <ErrorText msg={FORM_MSG.REQUIRED} />
                                                    </div>
                                                )}
                                        </>
                                    );
                                case "CreditReport":
                                    // if (validating && addProgram) return "";
                                    return (
                                        <>
                                            <SelectCreditReport
                                                key={f.title}
                                                id={person_id}
                                                validating={validating}
                                                value={options.credit_report}
                                                setValue={changeCreditReport}
                                                lead={lead}
                                            />
                                            {getErrorType("credit_report") === "required" && (
                                                <div className="w-full col-span-8">
                                                    <ErrorText msg={FORM_MSG.REQUIRED} />
                                                </div>
                                            )}
                                            <div className="w-full flex flex-col col-span-8">
                                                <p className="font-bold text-lg">
                                                    Credit Report Notes
                                                </p>
                                                <textarea
                                                    className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                                    focus:border-none transition-all rounded-[7px] py-1 px-2 mb-0 h-20 ${validating && "bg-reyna-grey-3"
                                                        }`}
                                                    {...register("notes_credit", {})}
                                                    disabled={validating}
                                                />
                                            </div>
                                        </>
                                    );
                                case "Collections":
                                    // if (validating && addProgram) return "";
                                    const collections = ["1", "2", "3"];
                                    return (
                                        <div className="flex flex-col col-span-8 -mb-4">
                                            <div className="lg:col-span-8 font-bold mb-0">
                                                Collections
                                            </div>
                                            {collections.map((c: any) => (
                                                <div
                                                    key={f.title + c}
                                                    className="grid grid-cols-8 gap-2"
                                                >
                                                    <div
                                                        className={`lg:col-span-2 col-span-3 w-full flex sm:flex-col items-center sm:items-start`}
                                                    >
                                                        <DropDownCreditors
                                                            title={"Creditor"}
                                                            inputClassName="w-full mt-2"
                                                            editing={isEditable}
                                                            className="w-full"
                                                            value={
                                                                (options as any)[`creditor_${c}`]
                                                            }
                                                            name={`creditor_${c}`}
                                                            onChangeFunction={(val) =>
                                                                setValue(
                                                                    `creditor_${c}` as any,
                                                                    val
                                                                )
                                                            }
                                                        />
                                                        {getErrorType(`creditor_${c}`) ===
                                                            "required" && (
                                                                <ErrorText msg={FORM_MSG.REQUIRED} />
                                                            )}
                                                    </div>
                                                    <div
                                                        className={`lg:col-span-3 col-span-3 flex flex-col items-start`}
                                                    >
                                                        <span className="mb-2">{"Account"}</span>
                                                        <InputRFH
                                                            editing={isEditable}
                                                            className="flex-col w-full"
                                                            titleClassname=""
                                                            name={`account_${c}` as any}
                                                            inputRegister={register}
                                                            validation={{
                                                                ...formHandleEmpty,
                                                            }}
                                                        />
                                                        {getErrorType(`account_${c}`) ===
                                                            "required" && (
                                                                <ErrorText msg={FORM_MSG.REQUIRED} />
                                                            )}
                                                    </div>
                                                    <div
                                                        className={`lg:col-span-3 col-span-2 w-full flex sm:flex-col items-center sm:items-start`}
                                                    >
                                                        <NumberFormatRFH
                                                            editing={isEditable}
                                                            title={"Collection Amount"}
                                                            name={`collection_amount_${c}`}
                                                            inputClassName="w-full mt-2 mb-0"
                                                            className={`flex-col mb-4`}
                                                            control={control}
                                                            prefix={"$"}
                                                            suffix={""}
                                                            validation={{
                                                                validate: (val) => !val || val > 0,
                                                            }}
                                                        >
                                                            <>
                                                                {getErrorType(
                                                                    `collection_amount_${c}`
                                                                ) === "required" && (
                                                                        <ErrorText
                                                                            msg={FORM_MSG.REQUIRED}
                                                                        />
                                                                    )}
                                                                {getErrorType(
                                                                    `collection_amount_${c}`
                                                                ) === "validate" && (
                                                                        <ErrorText
                                                                            msg={FORM_MSG.MIN(0)}
                                                                        />
                                                                    )}
                                                            </>
                                                        </NumberFormatRFH>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                            }
                        })}
                        {validating && (
                            <div className="w-full flex flex-col col-span-8">
                                <p className="font-bold text-lg">Validation Notes</p>
                                <textarea
                                    className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                                    focus:border-none transition-all rounded-[7px] py-1 px-2 mb-0 h-20 ${addProgram && "bg-reyna-grey-3"
                                        }`}
                                    {...register("notes_validation", {})}
                                    disabled={addProgram}
                                />
                            </div>
                        )}
                        {showInfo && (
                            <div className="w-full gap-2 grid col-span-8 grid-cols-2">
                                <InputRFH
                                    editing={false}
                                    className={`flex-col w-full col-span-1`}
                                    titleClassname=""
                                    title="Created by"
                                    name={"creator.name"}
                                    inputRegister={register}
                                />
                                {proposal ? (
                                    <DropdownDynamicList
                                        title={"Reason"}
                                        editing={false}
                                        name={"reason"}
                                        className="flex-col"
                                        outerClass={"lg:col-span-1 col-span-2"}
                                        titleClassName="text-r-medium-small-2"
                                        row={false}
                                        onChangeFunction={(val) => setValue("reason" as any, val)}
                                        placeholder={"Reason"}
                                        search={"Rejected Reason"}
                                        values={(options as any).reason}
                                    />
                                ) : (
                                    <InputRFH
                                        editing={false}
                                        className="flex-col w-full col-span-1"
                                        titleClassname=""
                                        title="Validated by"
                                        name={"validator.name"}
                                        inputRegister={register}
                                    />
                                )}
                            </div>
                        )}
                        {proposal && (
                            <div className="w-full flex flex-col col-span-8">
                                <p className="font-bold text-lg">Cancel Notes</p>
                                <textarea
                                    className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                                    focus:border-none transition-all rounded-[7px] py-1 px-2 mb-0 h-20 ${addProgram && "bg-reyna-grey-3"
                                        }`}
                                    {...register("notes_cancel", {})}
                                    disabled={addProgram}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={`flex flex-row ${backFunction || cancelFunction ? "justify-between" : "justify-end"
                            } w-full gap-8`}
                    >
                        {backFunction && (
                            <button
                                className="button-crm flex bg-reyna-blue text-white text-lg py-2 px-10"
                                onClick={(e) => goBack(e)}
                            >
                                {"Back"}
                            </button>
                        )}
                        {cancelFunction && (
                            <Button
                                title={"Cancel"}
                                className={
                                    "button-crm flex !bg-reyna-red text-white text-lg py-2 px-10"
                                }
                                clickFunction={cancelFunction}
                                isLoading={isUpdating}
                                type="button"
                            />
                        )}
                        {onSubmit && (
                            <Button
                                title={"Submit"}
                                className={
                                    "flex self-end bg-reyna-blue text-white text-lg py-2 px-10"
                                }
                                clickFunction={onSubmit}
                                isLoading={isUpdating}
                                type="button"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
