/**
 * This component is made to be used with React-Form-Hooks
 */
import { RegisterOptions, Controller, Control, ControllerRenderProps } from "react-hook-form";
import NumberFormat from "react-number-format";
import Input from "./Input";
import { InputRFH } from "./InputRFH";

type InputProps = {
    name: string;
    title?: string;
    control?: Control<any>;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    prefix?: string;
    suffix?: string;
    editing?: boolean;
    isDisabled?: boolean;
    inputRegister?: RegisterOptions<any>;
    validation?: RegisterOptions;
    children?: JSX.Element | false | undefined;
    decimalScale?: number;
};

export const NumberFormatRFH = ({
    name,
    title,
    control,
    placeholder,
    className,
    inputClassName,
    titleClassname,
    editing = true,
    inputRegister,
    prefix,
    suffix,
    validation = {},
    children,
    decimalScale = 2,
}: InputProps) => {
    const Formatter = ({
        field: { onChange, value, ...rest },
    }: {
        field: ControllerRenderProps<any, string>;
    }) => (
        value !== "******" ? (
            <NumberFormat
                {...rest}
                value={value === undefined || value === null ? "" : value}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                decimalScale={decimalScale}
                fixedDecimalScale
                prefix={prefix}
                suffix={suffix}
                onChange={(val: any) => onChange(val.target.value.replace(/["$",",","(",")"]/gi, "") || null)}
                onBlur={() => { }}
                onBlurCapture={() => { }}
                onChangeCapture={() => { }}
                onPointerLeave={() => { }}
                isNumericString
                placeholder={placeholder}
                disabled={!editing}
                className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                      focus:border-reyna-primary transition-all rounded-[7px] py-1 px-2 mb-4 h-[34px]
                      ${inputClassName ? inputClassName : " bg-white"} ${editing === false ? " bg-reyna-grey-3 " : ""
                    } `}
            />) : (
            <Input
                className="flex-col w-full pr-2"
                name="accounts_positive"
                inputClassName="mb-0"
                editing={editing}
                value={value || ""}
            />
        )
    );
    return (
        <div className={`flex  ${className ? className : "flex-col"}`}>
            {title && (
                <div className={`text-r-medium-small-2 ${titleClassname ? titleClassname : ""}`}>
                    <span className="block overflow-y overflow-x-hidden break-words">{title}</span>
                </div>
            )}
            <div className="w-full">
                <Controller
                    name={name}
                    control={control}
                    render={Formatter}
                    rules={{ ...inputRegister, ...validation }}
                />
                {/* You can pass a component to display stuff under the input. Its usually used for
                displaying error msges */}
                {children && children}
            </div>
        </div>
    );
};
