import { CalendarLogo, CancelIcon, CloseIcon } from "../../icons";
import DatePicker from 'react-datepicker';
import moment from "moment";

type InputProps = {
    onChange: (val: any) => void;
    title?: string;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    prefix?: string;
    suffix?: string;
    val?: string;
    editing?: boolean;
    children?: JSX.Element | false | undefined;
    emptyValue?: any,
};
export const convertStrToDate = (dateString: string) => {
    if (typeof dateString === 'string') {
        let date = new Date(dateString);
        if (/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(dateString)) {
            const [year, month, day] = dateString.split("-").map(Number);
            date = new Date(year, month - 1, day);;
        }
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        // return new Date(date.getTime() - userTimezoneOffset);
        return new Date(
            date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset),
        );
        // return new Date(parseInt(dateString, 10));
    }

    if (typeof dateString === 'number') {
        const date = new Date(dateString);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(
            date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset),
        );
        // return new Date(date.getTime() - userTimezoneOffset);
        // return new Date(dateString);
    }
    throw new Error('Invalid date format from convertStrToDate function');
};

export function DateInput({
    onChange,
    title,
    className,
    inputClassName,
    titleClassname,
    editing = true,
    children,
    val,
    emptyValue = null,
}: InputProps) {
    return (
        <div className={`flex flex-col ${className ? className : ""}`}>
            {title && (
                <div className={`text-r-medium-small-2 ${titleClassname ? titleClassname : ""}`}>
                    <span className="block overflow-y overflow-x-hidden break-words w-max">
                        {title}
                    </span>
                </div>
            )}
            <div className={`w-full relative`}>
                <div className="flex w-full">
                    <DatePicker
                        calendarStartDay={1}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="w-full"
                        className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                      focus:border-reyna-primary transition-all rounded-[7px] py-1 px-2 mb-4 h-[34px] ${!editing && " !bg-reyna-grey-3"}
                       bg-white  ${inputClassName}`}
                        selected={val ? convertStrToDate(val) : undefined}
                        onChange={(date: any) => {
                            onChange(moment(date).format('YYYY-MM-DD'));
                        }}
                        disabled={!editing}
                        autoComplete="off"
                        dropdownMode="select"
                        showYearDropdown
                        showMonthDropdown
                        fixedHeight
                        showPopperArrow={false}
                    />
                    {editing && (
                        <CloseIcon
                            className={`fill-current hover:!text-reyna-red !text-gray-500 absolute right-8 my-[7px] w-[20px] h-[20px] cursor-pointer ${!editing && "opacity-50"}`}
                            onClick={() => {
                                if (editing) {
                                    onChange(emptyValue);
                                }
                            }}
                        />
                    )}
                    <CalendarLogo
                        className={`absolute !text-gray-500 fill-current !cursor-default pointer-events-none right-2 my-[7px] w-[20px] h-[20px]`}
                    />
                </div>
                {/* You can pass a component to display stuff under the input. Its usually used for
                displaying error msges */}
                {children && children}
            </div>
        </div>
    );
}
