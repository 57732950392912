import { compile } from "path-to-regexp";

// If API call names does not start with PUT, POST, or DELETE, it
// means it is a GET request.

export const API = {
    ALL_LEADS: "/lead/all",
    CREATE_LEAD: "/lead/new",
    SINGLE_LEAD: "/lead/:id",
    DELETE_LEAD: "/lead/delete/:id",
    SINGLE_LEAD_OVERALL: "/lead/lead-overall/:id",
    PUT_SINGLE_LEAD: "/lead/update",
    UPDATE_DOCUMENT_PIN: "/documents/pin/:id",
    UPDATE_DOCUMENT_UNPIN: "/documents/unpin/:id",
    UPDATE_DOCUMENT_NAME: "/documents/update-name",
    LEAD_DOCUMENT_UPLOAD: "/lead/document/new",
    LEAD_DOCUMENT_VIEW: "/lead/document/view",
    LEAD_DOCUMENT_DELETE: "/lead/document/delete",
    LEAD_DOCUMENT_DOWNLOAD: "/lead/document/download",
    LEAD_DOCUMENT_ALL: "/lead/document/all", // requires query param of id,
    LEAD_NEW_7_DAYS: "/lead/new/7days",
    LEAD_UNCONTACTED: "/lead/new/uncontacted",
    PUT_LEAD_CONVERT_CLIENT: "/lead/client-conversion/:id",
    PUT_LEAD_DETAIL: "/lead/details/update",
    LEAD_FINANCIAL_DISCLOSURE: "/lead/financial-disclosure/:id",
    PUT_LEAD_FINANCIAL_DISCLOSURE: "/lead/financial-disclosure/update",
    CLIENT_DOCUMENT_UPLOAD: "/client/document/new",
    CLIENT_DOCUMENT_DELETE: "/client/document/delete",
    CLIENT_DOCUMENT_VIEW: "/client/document/view",
    CLIENT_DOCUMENT_DOWNLOAD: "/client/document/download",
    CLIENT_DOCUMENT_ALL: "/client/document/all", // requires query param of id,
    CLIENT_RECEIPT_ALL: "/client/payment/receipts/:id", // requires query param of id,
    CLIENT_FINANCIAL_DISCLOSURE: "/client/financial-disclosure/:id",
    CLIENT_NEW_7_DAYS: "/client/new/7days",
    PUT_CLIENT_FINANCIAL_DISCLOSURE: "/client/financial-disclosure/update",
    CLIENT_PAYMENTS: "/client/payment/:id",
    CLIENT_PAYMENT_METHOD: "/client/payment/payment-method/:id",
    CLIENT_FEE_REFERENCES: "/client/payment/fee-references/:program_id",
    PUT_CLIENT_CREATE_FEE: "/client/payment/new-fee",
    PUT_CLIENT_PAYMENTS: "/client/payment/update",
    PRINT_PAYMENT_TRANSACTION: "/client/payment/print",
    SEND_RECEIPT_PAYMENT_TRANSACTION: "/client/payment/send-receipt",
    GENERATE_RECEIPT_PAYMENT_TRANSACTION: "/client/payment/generate-receipt",
    CLIENT_SETTLEMENT: "/client/settlement/:id",
    NOTIFY_CLIENT_SETTLEMENT: "/client/settlement/notify",
    CLIENT_SETTLEMENT_CREDIT_CARD: "/client/settlement/credit-card/:id",
    PUT_CLIENT_SETTLEMENT: "/client/settlement/update",
    CLIENT_PROCESSING: "/client/processing/:id",
    PUT_CLIENT_PROCESSING: "/client/processing/update",
    CLIENT_PROCESSING_PLATFORM_ACCESS: "/client/processing-access/:id",
    PUT_CLIENT_PROCESSING_PLATFORM_ACCESS: "/client/processing-access/update",
    DELETE_CLIENT_PROCESSING_PLATFORM_ACCESS: "/client/processing-access/delete",
    PUT_CLIENT_PROCESSING_AUDIT_NOTE: "/client/processing/audit_note/update",
    CLIENT_PROGRAM: "/client/program/:id",
    PUT_CLIENT_PROGRAM: "/client/program/update",
    POST_CLIENT_PROGRAM: "/client/program/create",
    POST_CANCEL_CLIENT_PROGRAM: "/client/program/cancel",
    LEAD_PROGRAM: "/lead/program/:id",
    POST_LEAD_PROGRAM: "/lead/program/create",
    POST_CANCEL_LEAD_PROGRAM: "/lead/program/cancel",
    CLIENT_VIOLATION: "/client/violation/:id",
    PUT_CLIENT_VIOLATION: "/client/violation/update",
    PUT_CLAIM_VIOLATION: "/client/violation/claim",
    CLIENT_ACCOUNT: "/client/account/:id",
    CLIENT_PROGRAM_ACCOUNT: "/client/account/program/:id",
    PUT_CLIENT_ACCOUNT: "/client/account/update",
    CLIENT_TRADELINES: "/client/tradeline/:id",
    PUT_CLIENT_TRADELINES: "/client/tradeline/update",
    CLIENT_BANKRUPTCY: "/client/bankruptcy/:id",
    PUT_CLIENT_BANKRUPTCY: "/client/bankruptcy/update",
    CLIENT_FUNDING: "/client/funding/:id",
    PUT_CLIENT_FUNDING: "/client/funding/update",
    ALL_CLIENTS: "/client/all",
    ALL_CLIENTS_PROGRAMS: "/client/program-all",
    CREATE_CLIENT: "/client/new",
    SINGLE_CLIENT: "/client/:id",
    PUT_SINGLE_CLIENT: "/client/update",
    PUT_CLIENT_DETAIL: "/client/details/update",
    ALL_EMPLOYEES: "/employee/all",
    CREATE_EMPLOYEE: "/employee/new",
    SINGLE_EMPLOYEE: "/employee/:id",
    DELETE_EMPLOYEE: "/employee/delete/:id",
    PUT_SINGLE_EMPLOYEE: "/employee/update",
    EMPLOYEE_COMMISSION: "/employee/commission/:id",
    PUT_EMPLOYEE_COMMISSION: "/employee/commission/update",
    EMPLOYEE_DOCUMENT_ALL: "/employee/document/all", // requires query param of id,
    EMPLOYEE_DOCUMENT_UPLOAD: "/employee/document/new",
    EMPLOYEE_DOCUMENT_DELETE: "/employee/document/delete",
    EMPLOYEE_DOCUMENT_VIEW: "/employee/document/view",
    EMPLOYEE_DOCUMENT_DOWNLOAD: "/employee/document/download",
    EMPLOYEE_SIMPLE_ALL_DROPDOWN: "/employee/dropdown",
    ALL_USERS: "/user/all",
    USERS_ROUND_ROBIN: "/user/round-robin",
    PUT_ROUND_ROBIN: "/user/update/round-robin",
    CREATE_USER: "/user/new",
    SINGLE_USER: "/user/:id",
    REAL_TIME_USERS: "/user/real-time/:id",
    PUT_SINGLE_USER: "/user/update",
    PUT_USER_DISABLED: "/user/disabled",
    PUT_USER_REASSIGN: "/user/reassign",
    PUT_SINGLE_USER_PROFILE: "/user/profile",
    POST_IMAGE_PROFILE: "/user/profile-image",
    POST_NEW_USER: "/user/new",
    POST_PASSWORD_UPDATE: "/user/password",
    PROFILE: "/user/auth0/:id",
    USER_SIMPLE_ALL: "/user/simplified/all",
    USER_SIMPLE_ALL_DROPDOWN: "/user/dropdown/all",
    USER_SIMPLE_CALENDAR: "/user/dropdown/calendar",
    DELETE_USER: "/user/delete/:id",
    DELETE_CLIENT: "/client/delete/:id",
    DELETE_ACCOUNT: "/client/account/delete",
    DELETE_CREDIT_CARD: "/client/credit-card/delete",
    DELETE_SETTLEMENT: "/client/settlement/delete",
    DELETE_TRADELINE: "/client/tradeline/delete",
    DELETE_FEE: "/client/payment/fee/delete",
    DELETE_FEE_TRANSACTION: "/client/payment/fee-transaction/delete",
    DELETE_TRANSACTION: "/client/payment/transaction/delete",
    DELETE_PROGRAM: "/client/program/delete",
    DELETE_VIOLATION: "/client/violation/delete",
    DELETE_RE_TRANSACTION: "/client/payment/re-transaction/delete",
    DELETE_PROCESS: "/client/process/delete",
    DELETE_CREDIT_SCORES: "/client/credit-scores/delete",
    DELETE_CREDIT_LINE: "/client/funding/credit-line/delete",
    DELETE_LEAD_CREDIT_SCORES: "/lead/credit-scores/delete",
    DELETE_MORTGAGE: "/client/funding/mortgage/delete",
    DELETE_BANK_ACCESS: "/client/funding/bank-access/delete",
    ALL_LANGUAGES: "/language/all",
    ALL_LOCATIONS: "/location/all",
    ALL_ROLES: "/role/all",
    ALL_NOTES: "/note/all", // requires query param of id,
    POST_NOTE_NEW: "/note/new",
    LEAD_FLAG: "/lead/red-flag/:id",
    PUT_LEAD_FLAG: "/lead/red-flag/update",
    CLIENT_FLAG: "/client/red-flag/:id",
    PUT_CLIENT_FLAG: "/client/red-flag/update",
    ALL_ACTIVITIES: "/activity/all",
    SINGLE_ACTIVITY: "/activity/:id",
    ALL_CONTACTS: "/contact/all",
    SINGLE_CONTACT: "/contact/:id",
    ALL_CONVERSATIONS: "/conversation/all",
    LAST_SENDERS_CONVERSATIONS: "/conversation/last-senders/names",
    SINGLE_CONVERSATIONS: "/conversation/:id",
    SIGNED_URL: "/webhook/image_signed_url",
    CONTACT_NAMES: "/contact/names",
    SEND_EMAIL: "/webhook/email/send",
    SEND_EMAIL_ADMIN: "/webhook/email/admin",
    SEND_SMS: "/webhook/sms/send",
    DELETE_CONVERSATIONS: "/conversation/delete/:id",
    // ALL_SERVICES: "/status/service/all",
    // ALL_GOALS: "/status/goal/all",
    // ALL_PROGRAMS: "/status/program/all",
    // ALL_SETTLEMENT_STATUS: "/status/settlement/all",
    // ALL_ACCOUNT_STATUS: "/status/account/all",
    USER_TASK_GET: "/user/task/:id",
    USER_TASK_CREATE: "/user/task/new",
    USER_TASK_DELETE: "/user/task/delete",
    USER_TASK_UPDATE: "/user/task/update",
    USER_TASKS_SINGLE: "/user/task/month/single",
    USER_TASK_ALL: "/user/task/all/:month",
    USER_APPOINTMENT_GET: "/appointment/:id",
    USER_APPOINTMENT_CREATE: "/appointment/new",
    USER_APPOINTMENT_DELETE: "/appointment/delete",
    USER_APPOINTMENT_UPDATE: "/appointment/update",
    USER_APPOINTMENT_SINGLE: "/appointment/month/single",
    USER_APPOINTMENT_ALL: "/appointment/month/all/:month",
    CONTACT_SEARCH_ALL: "/contact/all/names",
    TEMPLATE_ALL: "/template/all",
    CREATE_TEMPLATE: "/template/new",
    SINGLE_TEMPLATE: "/template/:id",
    PUT_SINGLE_TEMPLATE: "/template/update",
    SINGLE_TEMPLATE_DELETE: "/template/delete",
    CAMPAIGN_ALL: "/campaign/all",
    SINGLE_CAMPAIGN: "/campaign/:id",
    SINGLE_CAMPAIGN_HISTORY: "/campaign/history/:id",
    CAMPAIGN_CREATE: "/campaign/new",
    PUT_SINGLE_CAMPAIGN: "/campaign/update",
    SINGLE_CAMPAIGN_DELETE: "/campaign/delete",
    PUT_CAMPAIGN_DISABLED: "/campaign/disabled",
    SEND_SMS_FILTER: "/campaign/sms/send_filter",
    SEND_EMAIL_FILTER: "/campaign/email/send_filter",
    SEND_SMS_BULK: "/campaign/sms/send",
    SEND_EMAIL_BULK: "/campaign/email/send",
    BULK_MESSAGE_FILTERS: "/campaign/bulk_filters",
    BULK_CONVERSATION_ALL: "/campaign/bulk/all",
    SINGLE_BULK_CONVERSATION: "/campaign/bulk/:id",
    // GET_LOCATION: "https://cors-anywhere.herokuapp.com/https://api.geoapify.com/v1/geocode/search",
    GET_LOCATION: "/geoapify/:postcode",
    CITY_DROPDOWN_SEARCH: "/geoapify/city",
    SETTING_ALL: "/dynamic_list/all",
    SINGLE_SETTING: "/dynamic_list/:id",
    BY_NAME_SETTING: "/dynamic_list/name",
    NEW_DYNAMIC_LIST_VALUE: "/dynamic_list/new",
    SINGLE_SETTING_DELETE: "/dynamic_list/delete",
    PUT_DYNAMIC_LIST: "/dynamic_list/update",
    CREDITOR_ALL: "/creditor/all",
    PUT_CREDITOR: "/creditor/update",
    CR_PRICING: "/settings/cr_pricing",
    PUT_CR_PRICING: "/settings/cr_pricing/update",
    NOTIFICATIONS: "/notification/all",
    NOTIFICATIONS_UNREAD: "/notification/unread",
    PUT_UNREAD_NOTIFICATION: "/notification/mark-unread/:id",
    PUT_READ_NOTIFICATION: "/notification/mark-read/:id",
    PUT_READ_ALL_NOTIFICATION: "/notification/mark-read/all",
    POST_PIN_NOTE: "/note/pin/:id",
    POST_UNPIN_NOTE: "/note/unpin/:id",
    UPDATE_NOTE: "/note/update",
    DELETE_NOTE: "/note/delete",
    GET_FILE: "/public/file/:id",
    GET_SIGN_TEMPLATES: "/sign/templates",
    GET_SIGN_TEMPLATES_FIELDS: "/sign/templates-fields",
    POST_SIGN_AGREEMENTS_FORM: "/sign/form",
    GET_SIGN_TEMPLATES_LEADS: "/sign/leads/templates",
    GET_SIGN_TEMPLATES_FIELDS_LEADS: "/sign/leads/templates-fields",
    POST_SIGN_AGREEMENTS_FORM_LEADS: "/sign/leads/form",
};

// API's for the bulk import & export server
export const API_BULK = {
    EXPORT: "/export",
    POST_IMPORT: "/import",
    REPORTS_ALL: "/report/all",
    POST_NEW_REPORT: "/report",
    DELETE_REPORT: "/report/:id",
    PREVIEW: "/preview",
};

/**
 *
 * @param apiUrl in pathToRegexp format
 * @param params key value pairs to build url
 * @param baseUrl the  baseurl eg. http://localhost:3000.
 * @param query query params in object format eg. {id : 1234}
 * @returns a complete url.
 */
export const buildUrl = (apiUrl: string, params = {}, baseUrl = "", query = {}) => {
    // @ts-ignore
    return `${baseUrl}${compile(apiUrl, { encode: encodeURIComponent })(params)}${
        Object.keys(query).length ? "?" : ""
    }${new URLSearchParams(query).toString()}`;
};
