export const ROUTES = {
    /**
     * All Lead routes
     */
    LEADS: "/lead",
    LEADS_DETAILS: "/leads/details",
    SINGLE_LEADS: "/lead/:id",
    NEW_SINGLE_LEADS: "/leads/new",
    /**
     * All Client routes
     */
    CLIENTS: "/client",
    CLIENTS_DETAILS: "/clients/details",
    CLIENTS_ACTIVITY: "/clients/activity",
    CLIENTS_PRODUCTION: "/clients/production",
    SINGLE_CLIENTS: "/client/:id",
    SINGLE_CLIENTS_TAB: "/client/:id/:tab",
    NEW_SINGLE_CLIENTS: "/clients/new",
    /**
     * All employee routes
     */
    EMPLOYEES: "/employee",
    SINGLE_EMPLOYEES: "/employee/:id",
    NEW_SINGLE_EMPLOYEES: "/employee/new",
    /**
     * All User routes
     */
    SINGLE_USERS: "/users/:id",
    NEW_SINGLE_USERS: "/user/new",
    USERS: "/users",
    USER_PROFILE: "/profile",
    /**
     * All Conversation routes
     */
    CONVERSATION: "/conversations",
    SINGLE_CONVERSATION: "/conversation/:id",
    NEW_SINGLE_CONVERSATION: "/conversation/new",
    /**
     *
     */
    /**
     * Activity Screen
     */
    ACTIVITY: "/activities",
    SINGLE_ACTIVITY: "/activity/:id",
    /**
     * Calendar Screens
     */
    CALENDAR: "/calendar",
    /**
     * Task Screens
     */
    NEW_TASK: "/tasks/new",
    NEW_TASK_CONTACT: "/tasks/new/:id",
    SINGLE_TASK: "/tasks/:id",
    /**
     * Appointment Screens
     */
    NEW_APPOINTMENT: "/appointment/new",
    NEW_APPOINTMENT_CONTACT: "/appointment/new/:id",
    SINGLE_APPOINTMENT: "/appointment/:id",
    /**
     * Home routes
     */
    HOME: "/",
    DASHBOARD: "/dashboard",
    /**
     * Campaigns screen
     */
    CAMPAIGNS: "/campaigns",
    NEW_CAMPAIGN: "/campaign/new",
    SINGLE_CAMPAIGN: "/campaign/:id",
    BULK_CONVERSATION: "/campaigns/bulk-message",
    NEW_BULK_CONVERSATION: "/campaigns/bulk-message/new",
    SINGLE_BULK_CONVERSATION: "/campaigns/bulk-message/:id",
    /**
     * Setting screen
     */
    SETTINGS: "/settings",
    NEW_SETTING: "/settings/new",
    SINGLE_SETTING: "/settings/:id",
    /**
    /**
     * Templates screen
     */
    TEMPLATES: "/campaigns/templates",
    NEW_TEMPLATE: "/campaigns/template/new",
    SINGLE_TEMPLATE: "/campaigns/template/:id",
    /**
     * Groups screen
     */
    GROUPS: "/groups",
    /**
     * Not found route AKA 404 page
     */
    NOT_FOUND: "*",
};
