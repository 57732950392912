import { useEffect, useState } from "react";

function useCheckAllDocumentTable(tableData: { [p: string]: any }[]) {
    const [checkedAll, setCheckedAll] = useState(false);
    const [data, setData] = useState(tableData);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);

    const updateOffset = (offset: number) => {

        setOffset(offset);
    };
    const updateCount = (count: number) => {
        setCount(count);
    };
    const toggleCheck = async (id: string) => {
        setData((prevState) => {
            const newState = [...prevState];
            const index = prevState.findIndex((row: any) => row.id === id);
            newState[index] = {
                ...newState[index],
                checked: !newState[index].checked,
            };
            return [...newState];
        });
    };
    const selectAll = (e: any) => {
        const isChecked = (e.target as HTMLInputElement).checked;
        setCheckedAll(isChecked);
        setData((prevState) => {
            const newState = [...prevState];
            newState.forEach((row, index) => {
                newState[index].checked = isChecked;
            });
            return newState;
        });
    };
    useEffect(() => {
        let allChecked = true;
        data.forEach((row, index) => {
            if (!row.checked) {
                allChecked = false;
            }
        });
        if (allChecked && data.length > 0) {
            setCheckedAll(true);
        } else {
            setCheckedAll(false);
        }
    }, [data]);
    useEffect(() => {
        setCheckedAll(checkedAll ? false : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    return {
        data,
        setData,
        toggleCheck,
        selectAll,
        checkedAll,
        updateOffset,
        offset,
        count,
        updateCount,
    };
}

export default useCheckAllDocumentTable;
