// TODO: Define interface: https://swr.vercel.app/docs/options

export const SWR_CONFIG = {
    FORMS: {
        refreshInterval: 0, // Do not refresh for forms,
        revalidateOnFocus: false, // Do not refresh on focus, eg changing browser tabs
        errorRetryCount: 2,
    },
    NOTIFICATIONS: {
        refreshInterval: 0, // Do not refresh for forms,
        revalidateOnFocus: false, // Do not refresh on focus, eg changing browser tabs
        errorRetryCount: 2,
        dedupingInterval: 0,
    },
    // For list, get new data every 60 seconds
    FEEDBOX_NOTES: {
        refreshInterval: 60000,
        errorRetryInterval: 10000,
        errorRetryCount: 5,
    },
    NOTIFICATIONS_AP: {
        refreshInterval: 1000 * 600,
        errorRetryInterval: 20000,
        revalidateOnFocus: false,
        errorRetryCount: 5,
    }, //10 minutes = 600 seconds
    NOTIFICATIONS_24: {
        refreshInterval: 1000 * 86400,
        errorRetryInterval: 20000,
        revalidateOnFocus: false,
        errorRetryCount: 5,
    }, //24 hours = 86400 seconds
    CONVERSATION: { refreshInterval: 1000 * 60 * 10 }, // 10 minutes
    DELAYEDFETCH: { refreshInterval: 15000 },
    LIST: {
        refreshInterval: 30000,
        errorRetryInterval: 60000,
        errorRetryCount: 5,
    },
    RED_FLAG: {
        refreshInterval: 20000,
        errorRetryInterval: 20000,
        errorRetryCount: 5,
    },
    EXTERNAL: {
        external: true,
        refreshInterval: 0, // Do not refresh for forms,
        revalidateOnFocus: false, // Do not refresh on focus, eg changing browser tabs
        errorRetryCount: 2,
    },
};
