import { Store } from "react-notifications-component";
import { datadogRum } from "@datadog/browser-rum";
export type NotificationMessagesType = {
    OK: NotificationType;
    KO: NotificationType;
};

export type NotificationType = {
    title: string;
    message: string;
    type: NotificationTypeType;
    time?: number;
};

export type NotificationTypeType = "success" | "danger" | "warning" | "info" | "default";

export const openNotification = (n: NotificationType) => {
    Store.addNotification({
        title: n.title,
        message: n.message,
        type: n.type,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: n.time || 4000,
            onScreen: true,
        },
    });
};

const successType: NotificationTypeType = "success";
const dangerType: NotificationTypeType = "danger";
const infoType: NotificationTypeType = "info";
const warningType: NotificationTypeType = "warning";

export const notificationApiError = (message: string) => {
    //Currently we are not using message but can be used in future
    openNotification({
        title: "Error",
        message: "Failed to fetch data",
        type: dangerType,
    });
    datadogRum.addError("Failed to fetch data", {});
};
export const notificationToShow = (error: any, msg: any) => {
    if (error) {
        openNotification(msg["KO"]); // Show fail notification
    } else {
        openNotification(msg["OK"]); // show success notification
    }
};

export const notifyIntergerError = (errorMessage: string) => {
    return {
        title: "Input Error",
        message: `${errorMessage} is not a valid number`,
        type: dangerType,
    };
};

export const notifyIntergerTooBigError = (errorMessage: string) => {
    return {
        title: "Input Error",
        message: `${errorMessage} number is too big`,
        type: dangerType,
    };
};

export const NOTIFY_TEXT_LENGTH_ERROR = {
    title: "Input Error",
    message: `text is too long`,
    type: dangerType,
};

export const NOTIFY_FORBIDDEN_ERROR = {
    title: "Insufficient Permission",
    message: `You do not have the right permission for this operation`,
    type: warningType,
};

export const NOTIFY_NON_UNIQUE_RECEIPT_NUMBER = {
    title: "Input Error",
    message: "Receipt number is not unique, leave empty to auto-generate.",
    type: dangerType,
};

export const NOTIFY_REPORT_GENERATED = {
    OK: {
        title: "Done!",
        message: "Your report has been generated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_VIEW_GENERATED = {
    OK: {
        title: "Done!",
        message: "Your file view has been generated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_DATE_ERROR = {
    title: "Input Error",
    message: "Date is not valid",
    type: dangerType,
};

export const NOTIFY_NETWORK_ERROR = {
    title: "Error",
    message: "This error will be reported. Try again later.",
    type: dangerType,
};

export const NOTIFY_TASK_CREATED = {
    OK: {
        title: "Saved!",
        message: "The task has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The task has not been modified.",
        type: infoType,
    },
};


export const NOTIFY_TASK_COMPLETED = {
    OK: {
        title: "Saved!",
        message: "The task has been marked as completed.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The task has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_TASK_DELETED = {
    OK: {
        title: "Saved!",
        message: "The task has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The task has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_APPOINTMENT_CREATED = {
    OK: {
        title: "Saved!",
        message: "The appointment has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The appointment has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_APPOINTMENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The appointment has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The appointment has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_LEAD_SAVED = {
    OK: {
        title: "Saved!",
        message: "The Lead has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const EMAIL = {
    OK: {
        title: "Success!",
        message: "Email has been sent to the selected contacts.",
        type: successType,
    },
    KO: {
        title: "Error!",
        message: "Email has not been sent to the selected contacts.",
        type: dangerType,
    },
};
export const SMS = {
    OK: {
        title: "Success!",
        message: "Text Message has been sent to the selected contacts.",
        type: successType,
    },
    KO: {
        title: "Error!",
        message: "Text Message has not been sent to the selected contacts.",
        type: dangerType,
    },
};

export const NOTIFY_PREVIEW = {
    OK: {
        title: "Done!",
        message: "Showing matching results found.",
        type: successType,
    },
    OK_BUT_NONE: {
        title: "Done!",
        message: "No matching results found.",
        type: warningType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_REPORT_DELETED = {
    OK: {
        title: "Done!",
        message: "Report has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_LEAD_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The Lead has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};
export const CONVERSATION_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The conversation has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The conversation has not been deleted.",
        type: infoType,
    },
};

export const NOTIFY_LEAD_CONVERTED = {
    OK: {
        title: "Deleted!",
        message: "The Lead has been converted to a client successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_PRINT_PAYMENT = {
    KO: {
        title: "No receipt number",
        message:
            "We cannot print the receipt without a receipt number, please save the transaction to autogenerate it and print the receipt.",
        type: dangerType,
        time: 10000,
    },
};

export const NOTIFY_SEND_RECEIPT = {
    OK: {
        title: "Success!",
        message: "Receipt has been sent to the client.",
        type: successType,
    },
    KO: {
        title: "Error!",
        message: "This operation went wrong. Make sure the client has a phone number.",
        type: dangerType,
    },
}

export const NOTIFY_USER_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The user has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The user has not been modified.",
        type: infoType,
    },
};
export const NOTIFY_TEMPLATE_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The template has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The template has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_CAMPAIGN_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The campaign has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The campaign has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_SETTING_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The Dynamic list has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Dynamic list has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_USER_UNAUTHORIZED = {
    KO: {
        title: "Unauthorized",
        message: "You can't delete admin or super admin.",
        type: dangerType,
    },
};

export const NOTIFY_CLIENT_SAVED = {
    OK: {
        title: "Saved!",
        message: "Report successfully saved.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The report was not saved.",
        type: infoType,
    },
};

export const NOTIFY_INVALID_FIELD = {
    title: "Warning",
    message: "A required field(*) is empty.",
    type: warningType,
};

export const NOTIFY_CLIENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Client has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_EMPLOYEE_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Employee has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_NOT_FOUND = {
    title: "Not Found!",
    message: "The information you're looking for does not exist in the database.",
    type: warningType,
};

export const NOTIFY_PROFILE_SAVED = {
    OK: {
        title: "Saved!",
        message: "Your profile details have been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_PROFILE_IMAGE_FAIL = {
    title: "Error",
    message: "Failed to save profile image. This incident has been notified to Devio.",
    type: dangerType,
};

export const NOTIFY_FLAG_SAVED = {
    OK: {
        title: "Saved!",
        message: "The users status has been updated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_CAMPAIGN_DISABLED = {
    OK: {
        title: "Saved!",
        message: "The campaign has been disabled successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_DOCUMENT_SAVED = {
    OK: {
        title: "Saved!",
        message: "The Document has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_DOCUMENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Document has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_USER_SAVED = {
    OK: {
        title: "Saved!",
        message: "User details has been updated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The user was not saved.",
        type: infoType,
    },
};
export const NOTIFY_USER_PASSWORD_SAVED = {
    OK: {
        title: "Saved!",
        message: "User password has been updated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    }
};
export const NOTIFY_NOTIFICATION = {
    AS_READ: {
        title: "Saved!",
        message: "Notification marked as read correctly",
        type: successType,
    },
    AS_UNREAD: {
        title: "Saved!",
        message: "Notification marked as unread correctly",
        type: successType,
    },
    ALL: {
        title: "Saved!",
        message: "All notifications marked as read correctly",
        type: successType,
    },
};
export const NOTIFY_DOCUMENT_PINNED = {
    OK: {
        title: "Saved!",
        message: "Document pinned correctly",
        type: successType,
    },
};
export const NOTIFY_NOTE_PINNED = {
    OK: {
        title: "Saved!",
        message: "Note pinned correctly",
        type: successType,
    },
};
export const NOTIFY_TEMPLATE_SAVED = {
    OK: {
        title: "Saved!",
        message: "Template has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The template was not saved.",
        type: infoType,
    },
};
export const NOTIFY_CAMPAIGN_SAVED = {
    OK: {
        title: "Saved!",
        message: "Campaign has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Campaign was not saved.",
        type: infoType,
    },
};

export const NOTIFY_SETTING_SAVED = {
    OK: {
        title: "Saved!",
        message: "Dynamic list has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Dynamic list was not saved.",
        type: infoType,
    },
};

export const NOTIFY_CONTACT_CREATED = {
    OK: {
        title: "Created!",
        message: "This contact has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    AE: {
        title: "Error",
        message: "This operation went wrong. Contact already exists.",
        type: dangerType,
    },
};

export const NOTIFY_NOTE_SAVED = {
    OK: {
        title: "Saved!",
        message: "Your note has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_NOTE_DELETED = {
    OK: {
        title: "Saved!",
        message: "Your note has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_GENERIC_ERROR = {
    title: "Error",
    message: "This operation went wrong. This incident has been notified to Devio.",
    type: dangerType,
};

export const NOTIFY_SETTLEMENTS_CALCULATIONS = {
    ENABLE: {
        title: "Automatic calculations",
        message: "The values on this settlement will be automatically filled.",
        type: infoType,
    },
    DISBLE: {
        title: "Manual calculations",
        message: "The values on this settlement will NOT be automatically filled.",
        type: infoType,
    },
};

export const NOTIFY_TOO_MANY_CLAIMED = {
    title: "Error",
    message: "You have five or more claimed violation in progress. Cannot claim more.",
    type: dangerType,
    time: 6000,
};

export const NOTIFY_VIOLATION_CLAIMED = {
    title: "Claimed!",
    message: "Client violation successfully claimed.",
    type: successType,
};

export const NOTIFY_SAVE_BEFORE_CLAIM = {
    title: "Warning",
    message: "Save before claiming a violation.",
    type: warningType,
};

export const NOTIFY_REAL_ESTATE_AGENT_EMPTY = {
    title: "Real Estate Agent Required",
    message: "Please select a real estate agent on the Overall tab.",
    type: warningType,

}
export const SAVE_BEFORE_DELETING = {
    title: "Warning!",
    message: "Save before deleting.",
    type: warningType,
};
export const NOTIFY_ACCOUNT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Account has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};
export const NOTIFY_CREDIT_SCORES_DELETED = {
    OK: {
        title: "Saved!",
        message: "The credit scores have been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};
export const NOTIFY_CREDIT_CARD_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Account has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_MORTGAGE_DELETED = {
    OK: {
        title: "Saved!",
        message: "The mortgage has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_BANK_ACCESS_DELETED = {
    OK: {
        title: "Saved!",
        message: "The bank access has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_PROCESSING_PLATFORM_ACCESS_DELETED = {
    OK: {
        title: "Saved!",
        message: "The platform access has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};
export const NOTIFY_PROCESSING_PLATFORM_ACCESS_SAVED = {
    OK: {
        title: "Saved!",
        message: "The platform access has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};


export const NOTIFY_VIOLATION_DELETED = {
    OK: {
        title: "Saved!",
        message: "The violation has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_SETTLEMENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The settlement has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_FEE_DELETED = {
    OK: {
        title: "Saved!",
        message: "The fee has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_FEE_TRANSACTION_DELETED = {
    OK: {
        title: "Saved!",
        message: "The fee transaction has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_TRANSACTION_DELETED = {
    OK: {
        title: "Saved!",
        message: "The transaction has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_TRADELINE_DELETED = {
    OK: {
        title: "Saved!",
        message: "The tradeline has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_CLIENT_CREDIT_LINE_DELETED = {
    OK: {
        title: "Saved!",
        message: "The credit line has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};


export const NOTIFY_PROCESS_DELETED = {
    OK: {
        title: "Saved!",
        message: "The process has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_PROGRAM_DELETED = {
    OK: {
        title: "Saved!",
        message: "The program has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_AGREEMENT_CREATED = {
    OK: {
        title: "Saved!",
        message: "The agreement has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};
